import React from 'react';
import './styles.less';

export const IconLogo = () => {
  return (
    <span className="icon logo">
      <svg
        viewBox="0 0 728 133"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <g
          id="Artboard"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd">
          <g id="logo-black" fill="currentColor" fillRule="nonzero">
            <path
              d="M92.7,95.9 C92.7,117.3 73.7,126 55.1,126 L0.9,126 L0.9,125.2 C10.6,125.2 11.4,118.9 11.6,106.5 L11.6,26.6 C11.4,14.2 10.6,7.9 0.9,7.9 L0.9,7 L48.7,7 C65.2,7 84.4,15.3 84.4,36.8 C84.4,54.3 71.6,63 56.9,65.5 C73.3,65.5 92.7,74.5 92.7,95.9 Z M37.1,7.9 C27.4,7.9 26.4,14.2 26.4,26.6 L26.4,65.5 C32.7,65.5 40.2,65.5 44.6,65.5 C57.4,65.5 68.1,58.2 68.1,36.8 C68.1,15.4 54.5,7.9 44.6,7.9 L37.1,7.9 Z M76.2,95.9 C76.2,74.5 61.4,66.3 51.6,66.3 C51.6,66.3 28.3,66.3 26.4,66.3 L26.4,106.4 C26.4,118.8 27.4,125.1 37.1,125.1 C42.2,125.1 47.8,125.1 51.5,125.1 C62.2,125.2 76.2,117.3 76.2,95.9 Z"
              id="Shape"></path>
            <path
              d="M192.5,97.3 L185.5,126 L103,126 L103,125.2 C112.7,125.2 113.7,118.9 113.7,106.5 L113.7,26.6 C113.7,14.2 112.7,7.9 103,7.9 L103,7 L180,7 L185.8,30.5 L185,30.5 C180.1,19.5 170.4,7.9 153.4,7.9 L139.3,7.9 C129.6,7.9 128.8,14.2 128.6,26.6 L128.6,66.4 L141,66.4 C154.3,66.4 164.8,56.9 164.8,45.5 L165.6,45.5 L165.6,88.2 L164.8,88.2 C164.8,76.8 154.3,67.3 141,67.3 L128.6,67.3 L128.6,106.6 C128.8,119 129.6,125.3 139.3,125.3 L158.7,125.3 C175.9,125.3 186.7,108.5 191.7,97.4 L192.5,97.4 L192.5,97.3 Z"
              id="Path"></path>
            <path
              d="M308.4,110 L308.4,112.5 C299.2,119.8 288.7,125.9 269.8,127.1 C234.4,129.3 203.8,109.2 200.6,72.2 C197.2,35 223.7,9 258.9,5.7 C269.6,4.8 283.2,7.4 293.1,9.8 C301.8,11.8 305.5,12.3 305.5,0.8 L306.4,0.8 L306.4,43.6 L305.5,43.6 C305.5,32.6 295.1,18.3 284.9,12.3 C276.6,7.9 267.4,5.8 258.9,6.5 C233.1,8.9 214.5,37.4 217.4,70.6 C220.3,103.6 242.9,126.9 269.6,126.2 C285.8,126 298.9,118.7 308.4,110 Z"
              id="Path"></path>
            <path
              d="M431.3,125.2 L431.3,126 L393.9,126 L393.9,125.2 C402.1,125.2 403.3,122 398.7,109.6 C397,105 392.2,92.6 387,78.8 L346.2,78.8 C343.3,86.1 341.3,91.4 340.8,92.7 C335.5,107.1 336.9,125.2 348.3,125.2 L348.3,126 L312,126 L312,125.2 C322.9,123.3 330.9,115.8 337.5,98.9 C343.3,84.1 373.4,7.1 373.4,7.1 L374.9,7.1 C374.9,7.1 407,91.6 411.6,102.5 C416,113.2 420.8,125.2 431.3,125.2 Z M386.6,77.9 C377.6,54.3 367.1,26.9 366.7,26.1 C360.6,41.7 352.1,63.5 346.5,77.9 L386.6,77.9 L386.6,77.9 Z"
              id="Shape"></path>
            <path
              d="M534.5,7 L534.5,7.9 C524.8,7.9 523.8,14 523.8,26.6 L523.8,89 C523.8,118.8 504.4,127.8 485,127.8 C466.5,127.8 442.2,118.8 442.2,89 L442.2,26.6 C442.2,14 441.2,7.9 431.5,7.9 L431.5,7 L467.7,7 L467.7,7.9 C458,7.9 457.2,14 457,26.6 L457,91 C457,112.9 469.1,126.5 485,126.5 C511.3,126.5 522.9,111.5 522.9,88.9 L522.9,26.5 C522.9,13.9 521.9,7.8 512.2,7.8 L512.2,7 L534.5,7 Z"
              id="Path"></path>
            <path
              d="M625.1,96.4 C625.1,117.3 607.9,127.7 584.8,127.7 C575.6,127.7 566.3,125.7 560.8,124.5 C555.5,123.1 546.5,120.8 546.5,132.3 L545.6,132.3 L545.6,89.5 L546.5,89.5 C546.5,99.4 557.9,126.9 584.7,126.9 C600.2,126.9 610.7,117 610.7,103.1 C610.7,73 548.5,71 548.5,35.1 C548.5,14.9 565.8,5.4 583.9,5.4 C593.4,5.4 601.2,8.5 608.7,10.3 C614.8,12 618,10.3 618,0.9 L618.8,0.9 L618.8,43.7 L618,43.7 C618,23 604.4,6.1 584.3,6.1 C573.3,6.1 561.5,12.7 561.5,29.2 C561.7,58 625.1,61.7 625.1,96.4 Z"
              id="Path"></path>
            <path
              d="M727.4,97.3 L720.4,126 L638,126 L638,125.2 C647.7,125.2 648.7,118.9 648.7,106.5 L648.7,26.6 C648.7,14.2 647.7,7.9 638,7.9 L638,7 L715,7 L720.8,30.5 L720,30.5 C715.1,19.5 705.4,7.9 688.4,7.9 L674.3,7.9 C664.6,7.9 663.8,14.2 663.6,26.6 L663.6,66.4 L676,66.4 C689.3,66.4 699.8,56.9 699.8,45.5 L700.6,45.5 L700.6,88.2 L699.8,88.2 C699.8,76.8 689.3,67.3 676,67.3 L663.6,67.3 L663.6,106.6 C663.8,119 664.6,125.3 674.3,125.3 L693.7,125.3 C710.9,125.3 721.7,108.5 726.7,97.4 L727.4,97.4 L727.4,97.3 Z"
              id="Path"></path>
          </g>
        </g>
      </svg>
    </span>
  );
};

export const IconCheckmark = (props) => {
  return (
    <span className="icon cart" {...props}>
      <svg
        width="14"
        height="13"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.57812 12.6621C5.94043 12.6621 6.21387 12.5117 6.41211 12.2178L12.8379 2.19629C12.9814 1.97754 13.0361 1.78613 13.0361 1.60156C13.0361 1.12988 12.708 0.801758 12.2295 0.801758C11.8945 0.801758 11.6963 0.917969 11.4912 1.23926L5.55078 10.6729L2.48828 6.74219C2.29004 6.47559 2.08496 6.36621 1.78418 6.36621C1.30566 6.36621 0.957031 6.70801 0.957031 7.17969C0.957031 7.38477 1.03223 7.58301 1.20312 7.79492L4.74414 12.2246C4.9834 12.5186 5.23633 12.6621 5.57812 12.6621Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconCart = (props) => {
  return (
    <span className="icon cart" {...props}>
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5471 14.5879C19.0481 14.5879 19.4934 14.2168 19.4934 13.6509C19.4934 13.0942 19.0481 12.7231 18.5471 12.7231H8.02661C7.64624 12.7231 7.40503 12.4541 7.34937 12.0552L7.21021 11.0996H18.6584C20.1335 11.0996 20.9221 10.2183 21.1355 8.73389L21.7942 4.29932L21.7995 4.26525C21.8176 4.14865 21.8406 4.00073 21.8406 3.89111C21.8406 3.27881 21.4231 2.85205 20.6716 2.85205H6.02271L5.9021 1.97998C5.77222 1.09863 5.39185 0.65332 4.28784 0.65332H1.17993C0.632568 0.65332 0.159424 1.12646 0.159424 1.68311C0.159424 2.24902 0.632568 2.71289 1.17993 2.71289H3.89819L5.28979 12.2407C5.51245 13.7065 6.29175 14.5879 7.76685 14.5879H18.5471ZM8.54614 19.3472C9.50171 19.3472 10.2625 18.5957 10.2625 17.6401C10.2625 16.6938 9.50171 15.9331 8.54614 15.9331C7.60913 15.9331 6.83911 16.6938 6.83911 17.6401C6.83911 18.5957 7.60913 19.3472 8.54614 19.3472ZM18.7442 17.6401C18.7442 18.5957 17.9835 19.3472 17.0372 19.3472C16.0909 19.3472 15.3209 18.5957 15.3209 17.6401C15.3209 16.6938 16.0909 15.9331 17.0372 15.9331C17.9835 15.9331 18.7442 16.6938 18.7442 17.6401Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconClose = (props) => {
  return (
    <span className="icon close" {...props}>
      <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.01855 16.1406C0.65332 16.5059 0.642578 17.1289 1.01855 17.4941C1.39453 17.8594 2.01758 17.8594 2.38281 17.4941L9.25781 10.6191L16.1328 17.4941C16.498 17.8594 17.1318 17.8701 17.4971 17.4941C17.8623 17.1182 17.8623 16.5059 17.4971 16.1406L10.6221 9.25488L17.4971 2.37988C17.8623 2.01465 17.873 1.3916 17.4971 1.02637C17.1211 0.650391 16.498 0.650391 16.1328 1.02637L9.25781 7.90137L2.38281 1.02637C2.01758 0.650391 1.38379 0.639648 1.01855 1.02637C0.65332 1.40234 0.65332 2.01465 1.01855 2.37988L7.89355 9.25488L1.01855 16.1406Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconCloseCircle = (props) => {
  return (
    <span className="icon close-circle" {...props}>
      <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6256 0.62559C12.1531 0.15311 11.387 0.153173 10.9145 0.625731L6.92352 4.61668L3.00893 0.702102C2.53645 0.229623 1.77035 0.229686 1.29779 0.702244C0.825235 1.1748 0.825171 1.94091 1.29765 2.41339L5.21223 6.32797L1.22128 10.3189C0.74872 10.7915 0.748657 11.5576 1.22114 12.0301C1.69362 12.5025 2.45972 12.5025 2.93228 12.0299L6.92323 8.03897L10.8378 11.9535C11.3103 12.426 12.0764 12.426 12.5489 11.9534C13.0215 11.4808 13.0216 10.7147 12.5491 10.2423L8.63451 6.32769L12.6255 2.33673C13.098 1.86417 13.0981 1.09807 12.6256 0.62559Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
      </svg>
    </span>
  );
};

export const IconPlay = (props) => {
  return (
    <span className="icon play" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.72119 19.0459C8.05322 19.0459 8.33545 18.9131 8.66748 18.7222L18.3462 13.1274C19.0352 12.7207 19.2759 12.4551 19.2759 12.0151C19.2759 11.5752 19.0352 11.3096 18.3462 10.9111L8.66748 5.30811C8.33545 5.11719 8.05322 4.99268 7.72119 4.99268C7.10693 4.99268 6.7251 5.45752 6.7251 6.17969V17.8506C6.7251 18.5728 7.10693 19.0459 7.72119 19.0459Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconPause = (props) => {
  return (
    <span className="icon pause" {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.97705 19.0112H10.8696C11.5918 19.0112 11.9736 18.6294 11.9736 17.8989V6.59326C11.9736 5.83789 11.5918 5.48926 10.8696 5.48926H8.97705C8.25488 5.48926 7.87305 5.87109 7.87305 6.59326V17.8989C7.87305 18.6294 8.25488 19.0112 8.97705 19.0112ZM15.0117 19.0112H16.896C17.6265 19.0112 18 18.6294 18 17.8989V6.59326C18 5.83789 17.6265 5.48926 16.896 5.48926H15.0117C14.2812 5.48926 13.8994 5.87109 13.8994 6.59326V17.8989C13.8994 18.6294 14.2812 19.0112 15.0117 19.0112Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconArrowDown = (props) => {
  return (
    <span className="icon arrow-down" {...props}>
      <svg viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.20898 6.14062C5.43994 6.14062 5.63867 6.04932 5.81592 5.87207L9.83887 1.74707C9.97852 1.60742 10.0537 1.43555 10.0537 1.23145C10.0537 0.8125 9.7207 0.479492 9.30713 0.479492C9.10303 0.479492 8.90967 0.560059 8.75928 0.710449L5.21436 4.36279L1.66406 0.710449C1.51367 0.56543 1.32568 0.479492 1.11621 0.479492C0.702637 0.479492 0.369629 0.8125 0.369629 1.23145C0.369629 1.43555 0.444824 1.60742 0.584473 1.75244L4.60742 5.87207C4.79541 6.05469 4.9834 6.14062 5.20898 6.14062Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconArrowBack = (props) => {
  return (
    <span className="icon arrow-back" {...props}>
      <svg
        viewBox="0 0 10 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.25 8.94922C0.25 9.19336 0.337891 9.4082 0.523438 9.59375L8.26758 17.1621C8.43359 17.3379 8.64844 17.4258 8.90234 17.4258C9.41016 17.4258 9.80078 17.0449 9.80078 16.5371C9.80078 16.2832 9.69336 16.0684 9.53711 15.9023L2.42773 8.94922L9.53711 1.99609C9.69336 1.83008 9.80078 1.60547 9.80078 1.36133C9.80078 0.853516 9.41016 0.472656 8.90234 0.472656C8.64844 0.472656 8.43359 0.560547 8.26758 0.726562L0.523438 8.30469C0.337891 8.48047 0.25 8.70508 0.25 8.94922Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconArrowForward = (props) => {
  return (
    <span className="icon arrow-forward" {...props}>
      <svg
        viewBox="0 0 11 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.8184 8.94922C10.8184 8.5293 10.6719 8.1875 10.3301 7.86523L2.83984 0.541016C2.58594 0.277344 2.27344 0.150391 1.89258 0.150391C1.14062 0.150391 0.525391 0.755859 0.525391 1.50781C0.525391 1.87891 0.681641 2.2207 0.955078 2.49414L7.5957 8.93945L0.955078 15.4043C0.681641 15.668 0.525391 16.0098 0.525391 16.3906C0.525391 17.1426 1.14062 17.748 1.89258 17.748C2.26367 17.748 2.58594 17.6211 2.83984 17.3574L10.3301 10.0332C10.6719 9.71094 10.8184 9.35938 10.8184 8.94922Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconActions = (props) => {
  return (
    <span className="icon actions" {...props}>
      <svg
        viewBox="0 0 14 4"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.26562 2.07129C3.26562 1.2168 2.60254 0.553711 1.74121 0.553711C0.907227 0.553711 0.223633 1.23047 0.223633 2.07129C0.223633 2.8916 0.907227 3.58887 1.74121 3.58887C2.56836 3.58887 3.26562 2.8916 3.26562 2.07129ZM8.38574 2.07129C8.38574 1.2168 7.72266 0.553711 6.86816 0.553711C6.03418 0.553711 5.35742 1.23047 5.35742 2.07129C5.35742 2.8916 6.03418 3.58887 6.86816 3.58887C7.69531 3.58887 8.38574 2.8916 8.38574 2.07129ZM13.5195 2.07129C13.5195 1.2168 12.8564 0.553711 12.002 0.553711C11.1611 0.553711 10.4775 1.23047 10.4775 2.07129C10.4775 2.8916 11.1611 3.58887 12.002 3.58887C12.8223 3.58887 13.5195 2.8916 13.5195 2.07129Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconList = (props) => {
  return (
    <span className="icon actions" {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.17871 8.03906C5.75977 8.03906 6.22461 7.57422 6.22461 7.00146C6.22461 6.42041 5.75977 5.95557 5.17871 5.95557C4.59766 5.95557 4.13281 6.42041 4.13281 7.00146C4.13281 7.57422 4.59766 8.03906 5.17871 8.03906ZM8.84766 7.66553H20.1865C20.5601 7.66553 20.8589 7.375 20.8589 7.00146C20.8589 6.61963 20.5601 6.3291 20.1865 6.3291H8.84766C8.46582 6.3291 8.17529 6.61963 8.17529 7.00146C8.17529 7.375 8.46582 7.66553 8.84766 7.66553ZM5.17871 13.061C5.75977 13.061 6.22461 12.5962 6.22461 12.0151C6.22461 11.4341 5.75977 10.9692 5.17871 10.9692C4.59766 10.9692 4.13281 11.4341 4.13281 12.0151C4.13281 12.5962 4.59766 13.061 5.17871 13.061ZM8.84766 12.6875H20.1865C20.5601 12.6875 20.8589 12.3887 20.8589 12.0151C20.8589 11.6416 20.5601 11.3511 20.1865 11.3511H8.84766C8.46582 11.3511 8.17529 11.6416 8.17529 12.0151C8.17529 12.3887 8.46582 12.6875 8.84766 12.6875ZM5.17871 18.083C5.75977 18.083 6.22461 17.6099 6.22461 17.0371C6.22461 16.4561 5.75977 15.9912 5.17871 15.9912C4.59766 15.9912 4.13281 16.4561 4.13281 17.0371C4.13281 17.6099 4.59766 18.083 5.17871 18.083ZM8.84766 17.7012H20.1865C20.5601 17.7012 20.8589 17.4106 20.8589 17.0371C20.8589 16.6553 20.5601 16.3647 20.1865 16.3647H8.84766C8.46582 16.3647 8.17529 16.6553 8.17529 17.0371C8.17529 17.4106 8.46582 17.7012 8.84766 17.7012Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconPlus = () => {
  return (
    <span className="icon plus">
      <svg
        viewBox="0 0 13 14"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.0546875 7.35938C0.0546875 7.74219 0.375 8.05469 0.75 8.05469H5.80469V13.1094C5.80469 13.4844 6.11719 13.8047 6.5 13.8047C6.88281 13.8047 7.20312 13.4844 7.20312 13.1094V8.05469H12.25C12.625 8.05469 12.9453 7.74219 12.9453 7.35938C12.9453 6.97656 12.625 6.65625 12.25 6.65625H7.20312V1.60938C7.20312 1.23438 6.88281 0.914062 6.5 0.914062C6.11719 0.914062 5.80469 1.23438 5.80469 1.60938V6.65625H0.75C0.375 6.65625 0.0546875 6.97656 0.0546875 7.35938Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconMinus = () => {
  return (
    <span className="icon minus">
      <svg viewBox="0 0 13 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.75 2.07031H12.25C12.625 2.07031 12.9453 1.75 12.9453 1.36719C12.9453 0.984375 12.625 0.671875 12.25 0.671875H0.75C0.375 0.671875 0.0546875 0.984375 0.0546875 1.36719C0.0546875 1.75 0.375 2.07031 0.75 2.07031Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconTrash = () => {
  return (
    <span className="icon trash">
      <svg
        viewBox="0 0 18 20"
        fill="currentcolor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.0845 17.5645L15.6323 5.70264H16.4873C16.9604 5.70264 17.3174 5.3457 17.3174 4.87256C17.3174 4.40771 16.9604 4.05078 16.4873 4.05078H12.8765V3.02148C12.8765 1.67676 11.9385 0.805176 10.4194 0.805176H7.56396C6.04492 0.805176 5.10693 1.67676 5.10693 3.02148V4.05078H1.5127C1.03955 4.05078 0.674316 4.40771 0.674316 4.87256C0.674316 5.3457 1.03955 5.70264 1.5127 5.70264H2.35938L2.91553 17.5728C2.98193 18.9258 3.82031 19.6978 5.18164 19.6978H12.8267C14.1714 19.6978 15.0181 18.9175 15.0845 17.5645ZM6.8501 3.08789C6.8501 2.63965 7.18213 2.32422 7.66357 2.32422H10.3281C10.8096 2.32422 11.1416 2.63965 11.1416 3.08789L11.1499 4.05078H6.8418L6.8501 3.08789ZM5.38916 18.0293C4.94092 18.0293 4.63379 17.7056 4.60889 17.2158L4.06934 5.70264H13.9058L13.3828 17.2158C13.3662 17.7139 13.0591 18.0293 12.6025 18.0293H5.38916ZM11.4321 16.7344C11.7642 16.7344 12.0215 16.4521 12.0298 16.0703L12.2788 7.71973C12.2954 7.33789 12.0298 7.04736 11.6978 7.04736C11.3657 7.04736 11.1001 7.34619 11.0918 7.71143L10.8428 16.062C10.8262 16.4355 11.0918 16.7344 11.4321 16.7344ZM6.55957 16.7344C6.9082 16.7344 7.16553 16.4355 7.15723 16.062L6.9082 7.71143C6.8916 7.34619 6.62598 7.04736 6.30225 7.04736C5.96191 7.04736 5.70459 7.33789 5.72119 7.71973L5.97021 16.0703C5.97852 16.4521 6.22754 16.7344 6.55957 16.7344ZM9 16.7344C9.32373 16.7344 9.59766 16.4355 9.59766 16.0703V7.71143C9.59766 7.34619 9.33203 7.04736 9 7.04736C8.66797 7.04736 8.39404 7.34619 8.39404 7.71143V16.0703C8.39404 16.4355 8.66797 16.7344 9 16.7344Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconVolumeOff = (props) => {
  return (
    <span className="icon volume-off" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.42139 15.3701H6.80371C6.87842 15.3701 6.94482 15.395 7.00293 15.4448L10.7466 18.7983C11.145 19.1553 11.4521 19.3213 11.8506 19.3213C12.3818 19.3213 12.7886 18.9312 12.7886 18.3916V5.6582C12.7886 5.11865 12.3818 4.67871 11.834 4.67871C11.4355 4.67871 11.1782 4.86133 10.7466 5.25146L7.00293 8.57178C6.94482 8.62158 6.87842 8.64648 6.80371 8.64648H4.42139C3.29248 8.64648 2.73633 9.21924 2.73633 10.4229V13.6021C2.73633 14.8057 3.30078 15.3701 4.42139 15.3701ZM18.6655 17.6943C18.9727 17.9019 19.3545 17.8271 19.5786 17.5117C20.6411 16.0508 21.2637 14.042 21.2637 11.9917C21.2637 9.94141 20.6494 7.91602 19.5786 6.47168C19.3545 6.15625 18.9727 6.08154 18.6655 6.28906C18.3584 6.49658 18.3086 6.88672 18.5493 7.22705C19.4541 8.50537 19.9771 10.2236 19.9771 11.9917C19.9771 13.7598 19.4375 15.4614 18.5493 16.7563C18.3169 17.0967 18.3584 17.4868 18.6655 17.6943ZM4.47949 14.1582C4.17236 14.1582 4.03125 14.0171 4.03125 13.71V10.3149C4.03125 9.99951 4.17236 9.8584 4.47949 9.8584H7.13574C7.35156 9.8584 7.51758 9.81689 7.7085 9.64258L11.2446 6.43018C11.2861 6.38867 11.3276 6.36377 11.3857 6.36377C11.4438 6.36377 11.4937 6.40527 11.4937 6.47998V17.5117C11.4937 17.5864 11.4438 17.6362 11.3857 17.6362C11.3442 17.6362 11.2944 17.6113 11.2446 17.5698L7.7085 14.374C7.51758 14.208 7.35156 14.1582 7.13574 14.1582H4.47949ZM15.5942 15.5278C15.8682 15.7188 16.2583 15.6606 16.4824 15.3369C17.1216 14.4985 17.5034 13.2617 17.5034 11.9917C17.5034 10.7217 17.1216 9.49316 16.4824 8.64648C16.2583 8.32275 15.8682 8.25635 15.5942 8.45557C15.2539 8.68799 15.2041 9.10303 15.4614 9.44336C15.9429 10.1074 16.2168 11.0288 16.2168 11.9917C16.2168 12.9546 15.9346 13.8677 15.4614 14.54C15.2124 14.8887 15.2539 15.2871 15.5942 15.5278Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconVolumeOn = (props) => {
  return (
    <span className="icon volume-on" {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.9514 14.314V5.82227C16.9514 5.28271 16.553 4.84277 15.9968 4.84277C15.5984 4.84277 15.3494 5.02539 14.9094 5.41553L11.282 8.63623L12.1702 9.53271L15.4075 6.59424C15.449 6.55273 15.4988 6.52783 15.5486 6.52783C15.6067 6.52783 15.6565 6.56934 15.6565 6.64404V13.019L16.9514 14.314ZM19.6492 20.3569C19.8982 20.606 20.3132 20.606 20.554 20.3569C20.803 20.0996 20.803 19.7012 20.554 19.4521L6.34302 5.24121C6.09399 4.99219 5.67896 4.99219 5.42993 5.24121C5.18091 5.48193 5.18091 5.90527 5.42993 6.146L19.6492 20.3569ZM8.59253 15.5342H10.9666C11.0413 15.5342 11.1077 15.5591 11.1658 15.6089L14.9094 18.9624C15.3162 19.3193 15.615 19.4854 16.0134 19.4854C16.3372 19.4854 16.6028 19.3359 16.7937 19.0537L15.8308 18.0991L11.8215 14.5049C11.6555 14.3555 11.5559 14.3223 11.3401 14.3223H8.65063C8.33521 14.3223 8.19409 14.1812 8.19409 13.874V10.4541L7.198 9.45801C7.00708 9.72363 6.89917 10.0972 6.89917 10.5869V13.7661C6.89917 14.9697 7.46362 15.5342 8.59253 15.5342Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconBigClose = (props) => {
  return (
    <span className="icon big-close" {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.1333 18.3818C6.42383 18.6641 6.90527 18.6641 7.1875 18.3818L12.5 13.0693L17.8125 18.3818C18.0947 18.6641 18.5845 18.6724 18.8667 18.3818C19.1489 18.0913 19.1489 17.6182 18.8667 17.3359L13.5542 12.0151L18.8667 6.70264C19.1489 6.42041 19.1572 5.93896 18.8667 5.65674C18.5762 5.36621 18.0947 5.36621 17.8125 5.65674L12.5 10.9692L7.1875 5.65674C6.90527 5.36621 6.41553 5.35791 6.1333 5.65674C5.85107 5.94727 5.85107 6.42041 6.1333 6.70264L11.4458 12.0151L6.1333 17.3359C5.85107 17.6182 5.84277 18.0996 6.1333 18.3818Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export const IconCard = (props) => {
  return (
    <span className="icon card" {...props}>
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.72314 15.104H17.0171C18.7603 15.104 19.6235 14.249 19.6235 12.5391V3.49951C19.6235 1.78955 18.7603 0.92627 17.0171 0.92627H2.72314C0.988281 0.92627 0.116699 1.78955 0.116699 3.49951V12.5391C0.116699 14.249 0.988281 15.104 2.72314 15.104ZM1.45312 3.57422C1.45312 2.71094 1.90967 2.2627 2.73975 2.2627H17.0005C17.814 2.2627 18.2871 2.71094 18.2871 3.57422V4.22998H1.45312V3.57422ZM2.73975 13.7676C1.90967 13.7676 1.45312 13.3276 1.45312 12.4644V6.11426H18.2871V12.4644C18.2871 13.3276 17.814 13.7676 17.0005 13.7676H2.73975ZM3.84375 12.1987H5.87744C6.36719 12.1987 6.69092 11.8667 6.69092 11.4019V9.86621C6.69092 9.39307 6.36719 9.06934 5.87744 9.06934H3.84375C3.354 9.06934 3.03027 9.39307 3.03027 9.86621V11.4019C3.03027 11.8667 3.354 12.1987 3.84375 12.1987Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};
