import React, { useEffect, useContext, useRef, useState } from 'react';

import { VideoContext } from 'contexts/video';

import { useContainerWidth } from '../ModalSheet/utils';

export function AdaptiveBackground({ containerRef }) {
  const video = useContext(VideoContext);
  const canvasRef = useRef(null);

  const [enabled, setEnabled] = useState(false);
  const width = useContainerWidth(containerRef?.current);

  useEffect(() => {
    setEnabled(width !== window.innerWidth);
  }, [width]);

  useEffect(() => {
    if (!video?.videoRef?.current) return;

    const ctx = canvasRef.current.getContext('2d');
    let interval;
    if (enabled && video.videoRef?.current?.ref?.current?.ref?.current) {
      clearInterval(interval);
      interval = setInterval(() => {
        ctx.drawImage(
          video.videoRef?.current?.ref?.current?.ref?.current,
          0,
          0,
          400,
          200,
          0,
          0,
          400,
          200
        );
      }, 15);
    } else {
      clearInterval(interval);
      ctx.clearRect(0, 0, 400, 200);
    }
    return () => {
      clearInterval(interval);
      ctx.clearRect(0, 0, 400, 200);
    };
  }, [enabled, video?.videoRef?.current]);

  return <canvas className="screen__background" ref={canvasRef} />;
}
