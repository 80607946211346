import React, { useReducer, createContext, useEffect, useContext } from 'react';

export const SheetsContext = createContext();

import { TrackingContext } from 'contexts/tracking';
import { VideoContext } from 'contexts/video';

import { Sheet } from 'components/Sheet';
import { CartDetails } from 'components/CartDetails';
import { Checkout } from 'components/Checkout';

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

export const SheetsProvider = ({ children }) => {
  const tracking = useContext(TrackingContext);
  const video = useContext(VideoContext);

  const [openCart, setCartOpen] = React.useState(false);
  const [openCheckout, setCheckoutOpen] = React.useState(false);

  const [wasOpen, setWasOpen] = React.useState(false);

  const onClose = () => {
    setCartOpen(false);
    setCheckoutOpen(false);
  };

  const onCheckoutClose = (success) => {
    // diddnt purchase
    if (!success) {
      tracking.addEvent('buyflow-exited');
    }

    onClose();
  };

  useEffect(() => {
    if (!wasOpen && openCart) {
      setWasOpen(true);
    }
  }, [openCart, openCheckout, wasOpen]);

  useEffect(() => {
    if (openCheckout || openCart) {
      video.pause();
    } else if (!video.ended) {
      video.play();
    }
  }, [openCart, openCheckout]);

  const values = {
    showCart: () => setCartOpen(true),
  };

  return (
    <SheetsContext.Provider value={values}>
      <Sheet
        open={openCart}
        root="cartDetails"
        fullscreen
        onClose={onClose}
        pages={[
          {
            header: {
              title: 'Cart',
            },
            id: 'cartDetails',
            props: {
              onCheckout: () => {
                setCartOpen(false);
                setCheckoutOpen(true);
              },
            },
            Component: CartDetails,
          },
        ]}
      />
      <Checkout open={openCheckout} onClose={onCheckoutClose} />
      {children}
    </SheetsContext.Provider>
  );
};
