import React, { useContext, useEffect } from 'react';

import './styles.less';
import { urlForImage } from 'utils/uploads';

import { ProductsContext } from 'contexts/products';
import { motion, AnimatePresence } from 'framer-motion';

function getEventProducts(event) {
  const { products = [] } = event;
  return products.flatMap((p) => {
    return [p, ...(p.subProducts || [])];
  });
}

export function getEventProduct(event, productId) {
  if (productId) {
    return getEventProducts(event).find((p) => {
      return p.id === productId;
    });
  }
}

export const ProductsOverlays = ({ onSelect }) => {
  const { products } = useContext(ProductsContext);
  const filteredProducts = products.filter((p) => p?.coverImage);

  return (
    <ul className={'player__products'}>
      <AnimatePresence initial={false}>
        {filteredProducts.map((product) => (
          <motion.li
            key={product.id}
            initial={{ opacity: 0, y: 20, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.4 } }}>
            <img
              onClick={() => {
                onSelect(product);
              }}
              className={'player__product'}
              src={urlForImage(product.coverImage, { width: 140 * 3 })}
            />
          </motion.li>
        ))}
      </AnimatePresence>
    </ul>
  );
};
