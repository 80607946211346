import React, { useReducer, createContext, useEffect, useMemo } from 'react';

import { customAlphabet } from 'nanoid';
import { INSIGHTS_API_URL } from 'utils/env';
import { request } from 'utils/api';
import { localStorage } from 'utils/storage';
import { useHistory } from 'react-router-dom';

export const TrackingContext = createContext();

const clientSessionIdKey = 'clientSessionId';

const nanoid = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  14
);

const clientSessionId = localStorage.getItem(clientSessionIdKey) || nanoid();

if (!localStorage.getItem(clientSessionIdKey)) {
  localStorage.setItem(clientSessionIdKey, clientSessionId);
}

const params = new URLSearchParams(document.location.search);
const linkShortId =
  params.get('sid') || localStorage.getItem('linkShortId') || undefined;

if (linkShortId) {
  localStorage.setItem('linkShortId', linkShortId);
}

function flushEvents(events) {
  if (!events.length) {
    return;
  }

  if (!INSIGHTS_API_URL) {
    return;
  }

  request({
    domain: INSIGHTS_API_URL,
    path: `/1/events`,
    method: 'POST',
    body: { events },
    headers: {
      'x-origin': 'web',
    },
  });
}

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

export const TrackingProvider = ({ children }) => {
  const history = useHistory();
  const [state, setState] = useLocalState({
    events: [],
    eventMeta: {},
  });

  useEffect(() => {
    const interval = setInterval(() => {
      flushEvents(state.events);
      setState({ events: [] });
    }, 1000);
    return () => clearInterval(interval);
  }, [state.events]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set('cid', clientSessionId);
    history.replace({ search: params.toString(), pathname: location.pathname });
  }, []);

  const value = useMemo(() => {
    return {
      setLastProductId: (productId) => {
        setState({
          productId,
        });
      },
      setEvent: (event) => {
        setState({
          event,
        });
      },
      setEventMeta: (eventMeta) => {
        setState({
          eventMeta,
        });
      },
    };
  }, []);

  value.addEvent = (type, props) => {
    const newEvent = {
      type,
      occurredAt: new Date(),
      clientSessionId,
    };
    if (state.productId) {
      newEvent.productId = state.productId;
    }
    if (Object.keys(state.eventMeta || {}).length) {
      newEvent.eventMeta = state.eventMeta;
    }
    if (state.event?.id) {
      newEvent.eventId = state.event?.id;
    }

    if (linkShortId) {
      newEvent.linkShortId = linkShortId;
    }
    Object.assign(newEvent, props);

    window?.gtag('event', type, newEvent);

    console.log('[insight]', type, newEvent);

    setState({
      events: [...state.events, newEvent],
    });
  };

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
};
