import React from 'react';
import { kebabCase, defer } from 'lodash';

export default class Component extends React.Component {
  constructor(props = {}) {
    super(props);
    this.name = kebabCase(this.constructor.name);
  }

  getProps(props) {
    return {
      ...this.passProps(props),
      className: this.getComponentClass(),
    };
  }

  passProps(props = {}) {
    // Remove known props
    if (this.constructor.propTypes) {
      Object.keys(this.constructor.propTypes).forEach((key) => delete props[key]);
    }
    if ('style' in this.props) {
      props.style = this.props.style;
    }
    return props;
  }

  // Route Events

  setupRouteEvents() {
    // React Router doesn't expose previous location when listening
    // to history, so need to store as a variable here.
    //
    // https://github.com/ReactTraining/history/issues/817
    this._prevLocation = this.props.location;

    this.detachHistory = this.props.history.listen((location, action) => {
      if (this.locationChanged(this._prevLocation, location)) {
        // Defer and check to see that the component has
        // not unmounted before firing the route change event.
        defer(() => {
          if (this.detachHistory) {
            this.fireRouteChange(action);
          }
        });
      }
      this._prevLocation = location;
    });
    this.fireRouteChange('INIT');
  }

  destroyRouteEvents() {
    if (this.detachHistory) {
      this.detachHistory();
      this.detachHistory = null;
    }
  }

  locationChanged(prevLocation, newLocation) {
    return prevLocation.pathname !== newLocation.pathname || prevLocation.search !== newLocation.search;
  }

  fireRouteChange(type) {
    if (this.routeDidUpdate) {
      this.routeDidUpdate(type);
    }
  }

  // Class Names

  getComponentClass = () => {
    return this.getClasses(this.name, this.getModifiers(), this.props.className).join(' ');
  };

  getElementClass = (name, ...modifiers) => {
    return this.getClasses(`${this.name}__${name}`, modifiers).join(' ');
  };

  getModifierClass = (modifier) => {
    return `${this.name}--${modifier}`;
  };

  getModifiers = () => {
    return [];
  };

  // Private

  getClasses(name, modifiers, extra) {
    const classes = [name, ...modifiers.filter((m) => m).map((m) => `${name}--${m}`)];
    if (extra) {
      classes.push(extra);
    }
    return classes;
  }
}
