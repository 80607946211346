import React from 'react';
import ReactDOM from 'react-dom';
import './styles.less';
import { IconTrash } from '../Icons';
import { motion } from 'framer-motion';

const variants = {
  offscreen: {
    y: 50,
  },
  onscreen: {
    y: 0,
    transition: {
      type: 'spring',
      bounce: 0.3,
      duration: 0.5,
    },
  },
};

export const CartActions = ({ trigger, onRemove, containerRef }) => {
  const [open, setOpen] = React.useState(false);

  const triggerComponent =
    trigger &&
    React.cloneElement(trigger, {
      onClick: () => setOpen(true),
    });

  return (
    <>
      {triggerComponent}
      {open &&
        ReactDOM.createPortal(
          <motion.div
            initial="offscreen"
            animate="onscreen"
            className="cart-actions">
            <div
              className="cart-actions__overlay"
              onClick={() => {
                setOpen(false);
              }}
            />
            <motion.div variants={variants} className="cart-actions__menu">
              <div
                className="cart-actions__item negative"
                onClick={() => {
                  setOpen(false);
                  onRemove();
                }}>
                <IconTrash />
                Remove item
              </div>
            </motion.div>
          </motion.div>,
          containerRef
        )}
    </>
  );
};
