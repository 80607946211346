import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './styles.less';

import { urlForImage } from 'utils/uploads';

import { formatUsd } from 'utils/formatting';
import { IconActions, IconCart, IconMinus, IconPlus } from '../Icons';
import { Button } from '../Button';
import { Loader } from '../Loader';
import { CartTotal } from '../CartTotal';
import { Container } from '../Container';
import { CartContext } from 'contexts/cart';
import { UserContext } from 'contexts/user';
import { CartActions } from '../CartActions';
import { Prompt } from '../Prompt';
import { request } from 'utils/api';
import { NotificationContext } from 'contexts/notification';
import { TrackingContext } from 'contexts/tracking';
import { trackOrder } from 'utils/tracking';
import { localStorage } from 'utils/storage';

import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';

async function createOrder(paymentMethodId) {
  return request({
    method: 'POST',
    path: `/1/orders`,
    body: {
      paymentMethodId: paymentMethodId,
    },
  });
}

async function confirmOrder(paymentIntentId, orderId) {
  return await request({
    method: 'POST',
    path: `/1/orders/confirm`,
    body: {
      paymentIntentId,
      orderId,
    },
  });
}

async function checkoutCart(cart, shippingAddress) {
  const shortId = localStorage.getItem('linkShortId') || undefined;

  return request({
    path: '/1/carts/checkout',
    method: 'POST',
    body: {
      productItems: cart.products.map(
        ({ product, quantity, eventId, discountId }) => {
          return {
            productId: product.id,
            eventId,
            discountId: discountId,
            quantity,
            shortId,
          };
        }
      ),
      shippingAddress,
    },
  });
}

async function updateUser(userData) {
  const { data: user } = await request({
    method: 'GET',
    path: '/1/users/me',
  });

  if (user.firstName == 'anon' && user.lastName == 'user') {
    const { data } = await request({
      method: 'PATCH',
      path: '/1/users/me',
      body: userData,
    });
    return user;
  }

  return user;
}

async function getShippingCost(cart, shippingAdddess) {
  return request({
    path: '/1/carts/draft',
    method: 'POST',
    body: {
      productItems: cart.products.map(
        ({ product, quantity, discountId, eventId }) => {
          return {
            productId: product.id,
            discountId,
            quantity,
            eventId,
          };
        }
      ),
      shippingAddress: shippingAdddess,
    },
  });
}

export const CartDetails = ({ onCheckout, containerRef, onClose }) => {
  const cart = useContext(CartContext);
  const user = useContext(UserContext);
  const notification = useContext(NotificationContext);
  const tracking = useContext(TrackingContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [paymentRequest, setPaymentRequest] = useState(null);
  const stripe = useStripe();

  function onCheckoutClick() {
    tracking.addEvent('buyflow-entered');
    onCheckout();
  }

  useEffect(() => {
    if (cart.loading || (!cart.preTaxAmount && !cart.amount)) {
      return;
    }
    setLoading(true);

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: cart.amount ? 'SubTotal' : 'Total',
        // we need to give amount for this to work, we dont have the correct amount yet due to no shipping address
        amount: cart.preTaxAmount || cart.amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestShipping: true,
    });

    pr.canMakePayment()
      .then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [cart.products, cart.loading]);

  useEffect(() => {
    if (!paymentRequest) return;

    paymentRequest.on('cancel', function () {
      tracking.addEvent('buyflow-exited');
    });

    paymentRequest.on('shippingaddresschange', async function (ev) {
      tracking.addEvent('buyflow-entered');
      cart.toggleBuyflowMode(true);
      try {
        await user.registerAnonymous();
      } catch (e) {
        ev.updateWith({ status: 'fail' });
        cart.toggleBuyflowMode(false);
        setError(e);
        return;
      }

      if (ev.shippingAddress.country !== 'US') {
        ev.updateWith({ status: 'invalid_shipping_address' });
      } else {
        // Perform server-side request to fetch shipping options

        const shippingAddress = ev.shippingAddress;

        try {
          const { data: cartData } = await getShippingCost(cart, {
            city: shippingAddress.city,
            state: shippingAddress.region,
            postalCode: shippingAddress.postalCode,
            countryCode: shippingAddress.country,
          });

          ev.updateWith({
            status: 'success',
            total: {
              label: 'Total',
              amount: cartData.amount,
            },
            displayItems: [
              {
                label: 'Subtotal',
                amount: cartData.preTaxAmount,
              },
              {
                label: 'Tax',
                amount: cartData.taxAmount,
              },
            ],
            shippingOptions: [
              {
                label: 'Free Delivery',
                amount: 0,
              },
            ],
          });
        } catch (e) {
          setError(e);
          ev.updateWith({ status: 'fail' });
          cart.toggleBuyflowMode(false);
        }
      }
    });

    paymentRequest.on('paymentmethod', async (ev) => {
      const firstName = ev.payerName.split(' ')[0];
      const lastName = ev.payerName.split(' ')[1];
      const { shippingAddress } = ev;

      let orderData;
      try {
        await updateUser({
          firstName,
          lastName,
          email: ev.payerEmail,
        });

        // dont care when its done
        user.boot();

        await checkoutCart(cart, {
          firstName,
          lastName,
          line1: shippingAddress.addressLine[0],
          line2: shippingAddress.addressLine[1],
          city: shippingAddress.city,
          state: shippingAddress.region,
          postalCode: shippingAddress.postalCode,
          countryCode: shippingAddress.country,
        });
        const response = await createOrder(ev.paymentMethod.id);
        orderData = response.data;
      } catch (err) {
        setError(err);
        ev.complete('fail');
        cart.toggleBuyflowMode(false);
        return;
      }

      tracking.addEvent('buyflow-completed');
      ev.complete('success');
      const { intent, order } = orderData;
      if (intent.status === 'requires_action') {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(intent.client_secret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          ev.complete('fail');
          cart.toggleBuyflowMode(false);
          return;
        }
      }
      // The payment has succeeded.

      if (intent.status === 'requires_capture') {
        await confirmOrder(intent.id, order.id);
      }

      trackOrder(tracking, order);

      cart.toggleBuyflowMode(false);
      cart.clear();
      user.setHasCart(false);
      notification.add({ title: 'Purchase Complete!' });
      onClose();
    });
  }, [paymentRequest]);

  return (
    <div className="cart-details">
      <Container>
        {error &&
          ReactDOM.createPortal(
            <Prompt
              title="Checkout Error"
              description={error.message}
              onClose={() => {
                setError(null);
              }}
            />,
            containerRef
          )}
        {!cart.products.length && (
          <div className="cart-details__empty">
            <IconCart />
            <h2>Your cart is empty</h2>
            <p>When you add products, they'll appear here.</p>
          </div>
        )}
        {cart.products.map(({ product, quantity, parent, discount }) => {
          const image = urlForImage(product.coverImage, { width: 120 * 3 });
          const maxQuantity = Math.min(
            (product.preOrder ? 10 : product.inventory) ||
              product.purchaseLimit,
            10
          );

          const isDeposit = product.preOrder && product.compareAtPrice;

          return (
            <div className="cart-details__item" key={product.id}>
              <div className="cart-details__item-image">
                <img src={image} />
              </div>
              <div className="cart-details__item-wrapper">
                <div className="row">
                  <div className="cart-details__item-description">
                    {parent ? (
                      <>
                        {' '}
                        <h2>{parent.name}</h2>
                        <h3>{product.name}</h3>
                      </>
                    ) : (
                      <h2>{product.name}</h2>
                    )}

                    {isDeposit && (
                      <div className="cart-details__item-price">
                        {formatUsd(product.compareAtPrice / 100)} (50% Deposit)
                      </div>
                    )}

                    <div className="cart-details__item-price">
                      US {formatUsd(product.price / 100)}
                    </div>
                  </div>
                  <div className="cart-details__item_action">
                    <CartActions
                      containerRef={containerRef}
                      onRemove={() => cart.removeProduct(product.id)}
                      trigger={<IconActions />}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="cart-details__item_qty">
                    <div className="cart-details__item-qty-label">
                      Qty {quantity}
                    </div>
                    <div className="group">
                      <button
                        disabled={quantity == 1}
                        onClick={() => cart.updateProduct(product.id, -1)}>
                        <IconMinus />
                      </button>
                      <div className="divider"></div>
                      <button
                        disabled={quantity >= maxQuantity}
                        onClick={() => cart.updateProduct(product.id, 1)}>
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Container>
      <Container>
        {cart.products.length > 0 && (
          <>
            <CartTotal onError={(error) => setError(error)} />
            {!cart.loading && !loading && (
              <>
                {paymentRequest ? (
                  <>
                    <PaymentRequestButtonElement
                      options={{
                        paymentRequest,
                        style: {
                          paymentRequestButton: {
                            type: 'check-out',
                            // One of 'default', 'book', 'buy', or 'donate'
                            // Defaults to 'default'
                          },
                        },
                      }}
                    />
                    <Button
                      style={{ marginTop: '4px' }}
                      basic
                      loading={cart.loading || loading}
                      fluid
                      onClick={onCheckoutClick}>
                      Pay with Card
                    </Button>
                  </>
                ) : (
                  <Button
                    primary
                    loading={cart.loading || loading}
                    fluid
                    onClick={onCheckoutClick}>
                    Checkout
                  </Button>
                )}
              </>
            )}
            {!cart.loading && loading && <Loader small dark />}
          </>
        )}
      </Container>
    </div>
  );
};
