import React from 'react';

import { urlForImage } from 'utils/uploads';
import './styles.less';

import { getProductPrice } from 'utils/pricing';

export const ProductCard = ({ product, onSelect = () => {} }) => {
  const image = urlForImage(product.coverImage, { width: 120 * 3 });

  const price = getProductPrice(product);
  const originalPrice = getProductPrice(product, true);
  const isDiscounted = product.preOrder && originalPrice;

  return (
    <div className="productCard" onClick={() => onSelect(product)}>
      <img className="productCard__image" src={image} />
      <div className="productCard__stats">
        <div className="productCard__name">{product.name}</div>

        {!isDiscounted && (
          <>
            {(product.discount || product.compareAtPrice) && (
              <div className="productCard__price original">{originalPrice}</div>
            )}
            <div className="productCard__price">{price}</div>
          </>
        )}

        {isDiscounted && (
          <>
            <div className="productCard__price deposit">
              {originalPrice}{' '}
              <span
                style={{
                  color: '#9b9b9b',
                }}>
                ({price} Deposit)
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
