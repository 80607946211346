import React, { useReducer, createContext, useEffect, useContext } from 'react';
import { UserContext } from './user';

export const CartContext = createContext();

import { request } from 'utils/api';
import { localStorage } from 'utils/storage';
import { useDebounce } from 'utils/hooks';

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

function getCartLocalState() {
  try {
    const cart = localStorage.getItem('cart');
    return cart ? JSON.parse(cart) : [];
  } catch (e) {}
  return [];
}

function getCount(products) {
  return products.reduce((acc, item) => acc + item.quantity, 0);
}

async function calculateTotals(products, shippingAddress) {
  if (shippingAddress) {
    // NOTE: this is weird, this is due to the path endpoint doesnt work like the rest of the api
    // it merge the body with existing cart, so removing an product can cause problems, so instead i just call clear
    // then patch endpoint works as expected
    await request({
      path: '/1/carts/clear',
      method: 'POST',
    });

    const shortId = localStorage.getItem('linkShortId') || undefined;
    const { data } = await request({
      path: '/1/carts',
      method: 'PATCH',
      body: {
        productItems: products.map(
          ({ product, quantity, eventId, discountId }) => {
            return {
              productId: product.id,
              quantity,
              shortId,
              eventId,
              discountId,
            };
          }
        ),
        shippingAddressId: shippingAddress.id,
      },
    });

    return {
      amount: data.amount,
      preTaxAmount: data.preTaxAmount,
      taxAmount: data.taxAmount,
      discountAmount: data.discountAmount,
    };
  } else {
    const { data } = await request({
      path: `/1/carts/draft`,
      method: 'POST',
      body: {
        productItems: products.map(
          ({ product, quantity, discountId, eventId }) => {
            return {
              productId: product.id,
              quantity,
              discountId,
              eventId,
              discountId,
            };
          }
        ),
      },
    });

    const totals = {
      preTaxAmount: data.preTaxAmount,
      amount: undefined,
      taxAmount: undefined,
      discountAmount: data.discountAmount,
    };
    if (data.checkoutMessage) totals.checkoutMessage = data.checkoutMessage;

    return totals;
  }
}

function getInitalState(products) {
  return {
    loading: products.length > 0,
    products,
    count: getCount(products),
    amount: undefined,
    preTaxAmount: undefined,
    taxAmount: undefined,
    error: undefined,
    checkoutMessage: undefined,
  };
}

export const CartProvider = ({ children }) => {
  const user = useContext(UserContext);
  const products = getCartLocalState() || [];

  const [state, setState] = useLocalState(getInitalState(products));

  useEffect(() => {
    // XXX dont store the full product just the ID
    // and restore product by fetching the event from the api and finding the product
    localStorage.setItem('cart', JSON.stringify(state.products));
  }, [state]);

  const debouncedProducts = useDebounce(state.products, 500);

  async function updateCart() {
    try {
      const data = await calculateTotals(state.products, user.shippingAddress);
      setState({ loading: false, ...data });
    } catch (e) {
      const newState = { loading: false, error: e };

      // SOLD out handling ... this needs work
      if (e.details?.length) {
        e.details.forEach((soldOutProduct) => {
          const product = state.products.find((product) => {
            return soldOutProduct.productId === product.product?.id;
          });

          if (product) {
            product.quantity = Math.min(
              soldOutProduct.inventory,
              product.quantity - 1
            );
          }
        });
        newState.loading = true;
        newState.products = state.products.filter((c) => c.quantity > 0);
        setState(newState);
        return;
      }
      setState(newState);
    }
  }

  useEffect(() => {
    setState({ loading: true, error: undefined });
  }, [state.products]);

  useEffect(() => {
    // prevent the cart from updating when the user is in the buy flow
    if (state.enteredBuyFlow) return;
    updateCart();
  }, [state.enteredBuyFlow, debouncedProducts, user.shippingAddress]);

  return (
    <CartContext.Provider
      value={{
        ...state,
        toggleBuyflowMode: (enabled) => {
          setState({ enteredBuyFlow: enabled });
        },
        addProduct: ({ newProduct, parent, quantity, eventId, discountId }) => {
          let products = [...state.products];
          const existingProduct = products.find(({ product }) => {
            return product.id === newProduct.id;
          });

          const differentCreatorAccount = products.find(
            (c) =>
              c.product.creatorAccount?.id !== newProduct.creatorAccount?.id
          );
          // reset the cart if the product is from a different creator
          if (differentCreatorAccount) {
            console.warn(
              'product is from different creator account, clearing cart',
              newProduct
            );
            products = [];
          }

          if (existingProduct) {
            existingProduct.quantity += quantity;
          } else {
            products.push({
              product: newProduct,
              discountId,
              parent,
              eventId,
              quantity,
            });
          }

          setState({
            products,
            count: getCount(products),
          });
        },
        removeProduct: (productId) => {
          const products = state.products.filter((item) => {
            return item.product.id !== productId;
          });
          setState({
            products,
            count: getCount(products),
          });
        },
        updateProduct: (productId, quantity) => {
          const products = state.products.map((item) => {
            if (item.product.id === productId) {
              const newQuantity = item.quantity + quantity;
              return {
                ...item,
                quantity: newQuantity < 1 ? 1 : newQuantity,
              };
            }
            return item;
          });
          setState({
            products,
            count: getCount(products),
          });
        },
        clear: () => {
          setState({
            products: [],
            count: 0,
            loading: false,
            amount: undefined,
            preTaxAmount: undefined,
            taxAmount: undefined,
          });
        },
      }}>
      {children}
    </CartContext.Provider>
  );
};
