import React, { useContext, useEffect } from 'react';

import { CartContext } from 'contexts/cart';

import { TrackingContext } from 'contexts/tracking';
import { SheetsContext } from 'contexts/sheets';

import { ProductCard } from '../../ProductCard';
import { Button } from '../../Button';
import { urlForImage } from 'utils/uploads';

import './event.less';

export const SheetEvent = ({ event, onNavigate }) => {
  const cart = useContext(CartContext);
  const tracking = useContext(TrackingContext);

  const sheets = useContext(SheetsContext);

  const productIdsArray = (
    event.streams.find((c) => c.type === 'main')?.products || []
  ).map((c) => c.productId);

  const productIdsSet = new Set(productIdsArray);
  const productIds = [...productIdsSet];

  const products = productIds
    .map((productId) => {
      return event.products.find(
        (product) =>
          product.subProducts.find((c) => c.id === productId) ||
          product.id === productId
      );
    })
    .filter(Boolean);

  useEffect(() => {
    tracking.addEvent('product-list-viewed');
  }, []);

  return (
    <div className="event-sheet">
      {event.creatorAccount.profileImage && (
        <img
          className="creator__image"
          src={urlForImage(event.creatorAccount.profileImage, {
            height: 50 * 3,
          })}
        />
      )}
      <div className="creator__name">{event.creatorAccount.name}</div>
      <h2>{event.name}</h2>

      {cart.products?.length > 0 && (
        <Button fluid primary onClick={() => sheets.showCart()}>
          Review Cart
        </Button>
      )}

      <div className="products">
        {(products || []).map((product) => (
          <div
            key={product.id}
            onClick={() =>
              onNavigate('event/product', product.id, { product })
            }>
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};
