import React from 'react';
import './styles.less';
import { IconCloseCircle } from '../Icons';

export const ButtonClose = (props) => {
  return (
    <div className="button-close" {...props}>
      <IconCloseCircle />
    </div>
  );
};
