import React, { useContext, useEffect, useRef } from 'react';
import './styles.less';
import { ButtonCart } from '../ButtonCart';

export const FloatingCartButton = () => {
  return (
    <div className="FloatingCartButton">
      <ButtonCart />
    </div>
  );
};
