import React, { useContext, useEffect, useReducer, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Prompt } from '../Prompt';
import './styles.less';
import { UserContext } from 'contexts/user';
import { Container } from '../Container';
import { Input } from '../Input';
import { Button } from '../Button';
import { request } from 'utils/api';

import './shipping.less';
import { loadScript } from 'utils/script';
import { GOOGLE_API_KEY } from 'utils/env';

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;

export const Shipping = ({ onNavigate }) => {
  const user = useContext(UserContext);
  const line1Ref = useRef();
  const line2Ref = useRef();

  const [state, setState] = useLocalState({
    loadingResources: true,
    loading: false,
  });

  async function loadMaps() {
    try {
      if (!window?.google?.maps) {
        await loadScript(GOOGLE_MAP_URL);
      }
    } catch (e) {
      // handle error
      console.error(e);
    }

    setState({
      loadingResources: false,
    });

    ///console.log('setup');
    const autocomplete = new google.maps.places.Autocomplete(line1Ref.current, {
      componentRestrictions: { country: ['us'] },
      fields: ['address_components'],
      types: ['address'],
    });
    line1Ref.current.focus();

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      let line1 = '',
        city = '',
        state = '',
        postalCode = '';

      // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number': {
            line1 = `${component.long_name} ${line1}`;
            break;
          }

          case 'route': {
            line1 += component.short_name;
            break;
          }

          case 'postal_code': {
            postalCode = `${component.long_name}${postalCode}`;
            break;
          }

          case 'postal_code_suffix': {
            postalCode = `${postalCode}-${component.long_name}`;
            break;
          }
          case 'locality':
            city = component.long_name;
            break;
          case 'administrative_area_level_1': {
            state = component.short_name;
            break;
          }
        }
      }

      setForm({
        line1,
        city,
        state,
        postalCode,
      });

      line2Ref.current.focus();
    });
  }

  React.useEffect(() => {
    loadMaps();
  }, []);

  const [form, setForm] = useLocalState({
    type: 'shipping',
    countryCode: 'US',
    line1: '',
    line2: '',
    state: '',
    city: '',
    postalCode: '',
  });

  async function getAddresses() {
    setState({ loading: true });

    try {
      await user.registerAnonymous();
      const { data } = await request({
        method: 'POST',
        path: '/1/addresses/search',
      });
      const item = data.find((c) => c.type === 'shipping');
      if (item) {
        setForm({ ...item });
      }
    } catch (e) {
      setState({ error: e });
    }
    setState({ loading: false });
  }

  useEffect(() => {
    getAddresses();
  }, []);

  const onSave = async () => {
    setState({ loading: true });
    try {
      if (!form.id) {
        await request({
          method: 'POST',
          path: `/1/addresses`,
          body: {
            ...form,
          },
        });
      } else {
        await request({
          method: 'PATCH',
          path: `/1/addresses/${form.id}`,
          body: {
            ...form,
          },
        });
      }
      onNavigate('checkout');
    } catch (e) {
      setState({ error: e });
    }
    setState({ loading: false });
  };

  return (
    <div className="checkout-shipping">
      {state.error &&
        ReactDOM.createPortal(
          <Prompt
            title="Failed to save"
            description={state.error.message}
            onClose={() => {
              setState({ error: null });
            }}
          />,
          document.body
        )}
      <Container>
        <Input
          fluid
          className="checkout-shipping__line1"
          label="Deliver to"
          inputRef={line1Ref}
          name="line1"
          required
          value={form.line1}
          onChange={(e, { value, name }) => setForm({ [name]: value })}
        />

        <Input
          fluid
          inputRef={line2Ref}
          label="Apartment, unit, suite, or floor"
          name="line2"
          required
          value={form.line2}
          onChange={(e, { value, name }) => setForm({ [name]: value })}
        />

        <Input
          fluid
          label="City"
          required
          value={form.city}
          name="city"
          onChange={(e, { value, name }) => setForm({ [name]: value })}
        />

        <Input
          fluid
          label="State"
          required
          value={form.state}
          name="state"
          onChange={(e, { value, name }) => setForm({ [name]: value })}
        />

        <Input
          fluid
          label="Postal Code"
          required
          value={form.postalCode}
          name="postalCode"
          onChange={(e, { value, name }) => setForm({ [name]: value })}
        />
      </Container>
      <Container>
        <Button loading={state.loading} fluid primary onClick={onSave}>
          Save
        </Button>
      </Container>
    </div>
  );
};
