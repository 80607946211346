import React, { useContext } from 'react';

import { Button } from '../../Button';
import { urlForImage } from 'utils/uploads';
import { NotificationContext } from 'contexts/notification';

import './share.less';

export const SheetShare = ({ event, onNavigate }) => {
  const notification = useContext(NotificationContext);

  function share(event) {
    if (navigator.share) {
      return navigator.share({
        title: event.name,
        text: event.description,
        url: `${window.location.origin}/event/${event.id}`,
      });
    } else {
      navigator.clipboard.writeText(
        `${window.location.origin}/event/${event.id}`
      );
      notification.add({ title: 'Link Copied' });
    }
  }

  return (
    <div className="event-share">
      <img
        className="creator__image"
        src={urlForImage(event.creatorAccount.profileImage, {
          height: 50 * 3,
        })}
      />
      <div className="creator__name">{event.creatorAccount.name}</div>
      <h2>{event.name}</h2>
      <div className="event-share__description">{event.description}</div>
      <Button fluid primary onClick={() => share(event)}>
        Share Event
      </Button>
    </div>
  );
};
