import React, {
  useReducer,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { IconArrowDown, IconBigClose } from '../Icons';

import { SheetEvent, SheetProduct, SheetShare, SheetProducts } from './Sheets';

import './styles.less';

import { Timeline } from './Timeline';
import classNames from 'classnames';
import { Video } from './Video';
import { Seekbar } from './Seekbar';
import { ProductsOverlays } from './ProductsOverlays';
import { ButtonCart } from '../ButtonCart';
import { Button } from '../Button';
import { ProductDetails } from '../ProductDetails';
import { VideoContext } from 'contexts/video';

import { Sheet } from '../Sheet';
import { VideoControls } from './VideoControls';

import { TrackingContext } from 'contexts/tracking';

const ButtonClose = ({ onClick }) => {
  return (
    <Button control onClick={onClick}>
      <IconBigClose />
    </Button>
  );
};

export function getEventProduct(event, productId) {
  if (productId) {
    return getEventProducts(event).find((p) => {
      return p.id === productId;
    });
  }
}

function getEventProducts(event) {
  const { products = [] } = event;
  return products.flatMap((p) => {
    return [p, ...(p.subProducts || [])];
  });
}

function fullScreen() {
  const el = document.documentElement;
  if (navigator.platform === 'MacIntel') {
    return;
  }
  if (el.requestFullscreen) {
    window.enabledFullScreen = true;
    el.requestFullscreen();
  }
}

function exitFullScreen() {
  if (document.exitFullscreen && window.enabledFullScreen) {
    document.exitFullscreen();
  }
}

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

export const Player = ({ event, onClose }) => {
  const video = useContext(VideoContext);

  const tracking = useContext(TrackingContext);

  const [state, setState] = useLocalState({
    duration: null,
    showPlayer: true,
    loading: false,
    error: null,
    sheetId: null,
    message: null,
  });

  const rootRef = useRef();

  useEffect(() => {
    fullScreen(rootRef.current);
    return () => {
      exitFullScreen();
    };
  }, []);

  useEffect(() => {
    if (!state.sheetType) {
      setTimeout(() => {
        setState({
          sheetId: Date.now(),
        });
      }, 200);
    }
  }, [state.sheetType]);

  useEffect(() => {
    const timer = Date.now();
    tracking.addEvent('event-entered');

    return () => {
      tracking.addEvent('event-exited', {
        secondsSinceEntry: (Date.now() - timer) / 1000,
      });
    };
  }, [tracking]);

  const chapters = [
    ...(event.streams.find((c) => c.type === 'main')?.chapters || []),
  ].sort((a, b) => a.start - b.start);

  const stream = event.streams.find((s) => s.type === 'main');

  const onFullScreen = useCallback(
    (isFullScreen) => {
      if (!video.paused && isFullScreen) {
        video.pause();
      } else if (!video.ended) {
        video.play();
      }
    },
    [video.ended, video.paused]
  );

  function onSheetClose() {
    setState({ sheetType: video.ended ? 'event' : null });
  }

  useEffect(() => {
    // show end of video sheet
    if (!state.sheetType && video.ended) {
      setState({
        sheetType: 'event',
      });
    }
  }, [video.ended]);

  function showSheet(type, object) {
    if (video.ended) return;
    setState({
      sheetType: type,
      sheetObject: object,
    });
  }

  return (
    <div ref={rootRef} className="player-stage">
      <div
        className={classNames('player', {
          visible: state.showPlayer,
          showSheet: state.sheetType && state.sheetType !== 'event',
          ended: state.sheetType === 'event',
        })}>
        <div className="player__overlay">
          <div className="player__top">
            <Seekbar chapters={chapters} />
            <Timeline chapters={chapters} />

            <div className="player__titleBar">
              <div
                className="title"
                onClick={() => {
                  showSheet('share');
                }}>
                <div className="text">{event.name}</div>
                <IconArrowDown />
              </div>

              <ButtonCart />
              <ButtonClose onClick={() => onClose()} />
            </div>
          </div>

          {!state.showSheet && (
            <>
              <ProductsOverlays
                event={event}
                onSelect={(product) => {
                  showSheet('product', product);
                }}
              />
              <VideoControls
                onList={() => {
                  showSheet('products', event);
                }}
              />
            </>
          )}
        </div>

        <div className="player__video" onClick={onSheetClose}>
          <Video autoPlay video={stream?.video} />
        </div>

        <Sheet
          key={state.sheetId}
          open={state.sheetType === 'event'}
          root="event"
          snapPoints={[1, 0.65]}
          onFullScreen={onFullScreen}
          event={event}
          pages={[
            {
              id: 'event',
              Component: SheetEvent,
              header: {
                hasBack: false,
                hasClose: false,
              },
              children: [
                {
                  id: 'product',
                  Component: SheetProduct,
                  parent: 'event',
                  children: [
                    {
                      header: {
                        title: 'Product Details',
                        hasBack: true,
                        hasClose: false,
                      },
                      parent: 'event/product',
                      id: 'details',
                      Component: ProductDetails,
                    },
                  ],
                },
              ],
            },
          ]}
        />

        <Sheet
          key={`products-${state.sheetId}`}
          open={state.sheetType === 'products'}
          onFullScreen={onFullScreen}
          root="root"
          onClose={onSheetClose}
          event={event}
          pages={[
            {
              id: 'root',
              Component: SheetProducts,
              header: {
                hasBack: false,
                hasClose: true,
              },
              children: [
                {
                  id: 'product',
                  Component: SheetProduct,
                  parent: 'root',
                  children: [
                    {
                      header: {
                        title: 'Product Details',
                        hasBack: true,
                        hasClose: false,
                      },
                      parent: 'root/product',
                      id: 'details',
                      Component: ProductDetails,
                    },
                  ],
                },
              ],
            },
          ]}
        />

        <Sheet
          key={`share-${state.sheetId}`}
          onFullScreen={onFullScreen}
          open={state.sheetType === 'share'}
          root="share"
          onClose={onSheetClose}
          event={event}
          pages={[
            {
              id: 'share',
              props: {
                showProducts: true,
              },
              Component: SheetShare,
            },
          ]}
        />

        <Sheet
          key={`product-${state.sheetId}`}
          onFullScreen={onFullScreen}
          open={state.sheetType === 'product'}
          root="product"
          onClose={onSheetClose}
          event={event}
          product={state.sheetObject}
          pages={[
            {
              id: 'product',
              Component: SheetProduct,
              children: [
                {
                  header: {
                    title: 'Product Details',
                    hasBack: true,
                    hasClose: false,
                  },
                  parent: 'product',
                  id: 'details',
                  Component: ProductDetails,
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};
