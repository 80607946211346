import React, { useContext, useEffect } from 'react';
import './styles.less';

import { Overview } from './Overview';
import { Account } from './Account';
import { Card } from './Card';
import { Shipping } from './Shipping';
import { Sheet } from '../Sheet';

export const Checkout = ({ open, onClose }) => {
  return (
    <Sheet
      open={open}
      className="checkout-sheet"
      root="checkout"
      onClose={onClose}
      fullscreen
      simpleSheet
      pages={[
        {
          id: 'checkout',
          header: {
            title: 'Checkout',
          },
          Component: Overview,
          children: [
            {
              header: {
                title: 'Account',
                hasBack: true,
                hasClose: false,
              },
              parent: 'checkout',
              id: 'account',
              Component: Account,
            },
            {
              header: {
                title: 'Shipping',
                hasBack: true,
                hasClose: false,
              },
              parent: 'checkout',
              id: 'shipping',
              Component: Shipping,
            },
            {
              header: {
                title: 'Wallet',
                hasBack: true,
                hasClose: false,
              },
              parent: 'checkout',
              id: 'card',
              Component: Card,
            },
          ],
        },
      ]}
    />
  );
};
