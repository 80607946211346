import React from 'react';
import './styles.less';
import { IconArrowBack } from '../Icons';

export const ButtonBack = (props) => {
  return (
    <div className="button-back" {...props}>
      <IconArrowBack />
    </div>
  );
};
