import React from 'react';
import { Component } from 'helpers';
import { registerLayout } from 'helpers/screen';

import './landing.less';

class LandingLayout extends Component {
  render() {
    return <main className="landing-layout">{this.props.children}</main>;
  }
}

registerLayout('landing', LandingLayout);
