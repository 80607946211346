import React from 'react';

import logoInverted from 'assets/logo-inverted.svg';
import { screen, Component } from 'helpers';
import { Container } from 'components';

@screen
export default class Landing extends Component {
  static layout = 'landing';
  static title = 'BECAUSE';
  render() {
    return (
      <Container className="event-invite">
        <div
          style={{
            position: 'absolute',
            zIndex: '2',
            top: '2vh',
            left: '2vw',
            bottom: '2vh',
            right: '2vw',
            margin: '0',
            backgroundColor: '#000',
          }}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <img
              className="logo"
              style={{
                maxWidth: '600px',
              }}
              src={logoInverted}
            />
            <p style={{ fontSize: '18px' }}>Because is only available on iOS</p>
          </div>
        </div>
      </Container>
    );
  }
}
