import React, { useContext } from 'react';

import { VideoContext } from 'contexts/video';
import { Button } from '../Button';
import {
  IconList,
  IconPause,
  IconPlay,
  IconVolumeOff,
  IconVolumeOn,
} from '../Icons';

const PlayBtn = ({ onClick }) => {
  return (
    <Button control onClick={onClick}>
      <IconPlay />
    </Button>
  );
};

const PauseBtn = ({ onClick }) => {
  return (
    <Button control onClick={onClick}>
      <IconPause />
    </Button>
  );
};

const MuteBtn = ({ onClick }) => {
  return (
    <Button control onClick={onClick}>
      <IconVolumeOff />
    </Button>
  );
};

const UnMuteBtn = ({ onClick }) => {
  return (
    <Button control onClick={onClick}>
      <IconVolumeOn />
    </Button>
  );
};

const ListBtn = ({ onClick }) => {
  return (
    <Button control onClick={onClick}>
      <IconList />
    </Button>
  );
};

export const VideoControls = ({ onList }) => {
  const { muted, paused, play, pause, mute } = useContext(VideoContext);

  return (
    <div className={'videoControls'}>
      {paused ? (
        <PlayBtn onClick={() => play()} />
      ) : (
        <PauseBtn onClick={() => pause()} />
      )}
      {muted ? (
        <UnMuteBtn onClick={() => mute(false)} />
      ) : (
        <MuteBtn onClick={() => mute(true)} />
      )}
      <ListBtn onClick={() => onList()} />
    </div>
  );
};
