import React, { useReducer, createContext, useEffect } from 'react';
import { request } from 'utils/api';
import { localStorage, sessionStorage } from 'utils/storage';

export const UserContext = createContext();

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

function fetchAddresses() {
  return request({
    method: 'POST',
    path: '/1/addresses/search',
  });
}

function fetchPaymentMethods() {
  return request({
    method: 'GET',
    path: '/1/payments/methods',
  });
}

function getPaymentMethod() {
  try {
    const paymentMethod = sessionStorage.getItem('paymentMethod');
    return JSON.parse(paymentMethod);
  } catch (e) {}
  return null;
}

export const UserProvider = ({ children }) => {
  const [state, setState] = useLocalState({
    token: localStorage.getItem('because-token'),
    addresses: [],
    shippingAddress: null,
    paymentMethod: getPaymentMethod(),
    hasCart: false,
  });

  useEffect(() => {
    const onRequestError = (event) => {
      if (event.detail.status === 401 && state.token) {
        setToken(null);
        // waiting for a bit to allow the user to see any error message
        setTimeout(() => {
          history.go(0);
        }, 1000);
      }
    };

    window.addEventListener('requestError', onRequestError);
    return () => {
      window.removeEventListener('requestError', onRequestError);
    };
  }, [state.token]);

  async function boot() {
    if (!state.token) return;

    const options = {};
    try {
      const [{ data: me }, { data: addresses }, { data: paymentMethods }] =
        await Promise.all([
          request({
            method: 'GET',
            path: '/1/users/me',
          }),
          fetchAddresses(),
          fetchPaymentMethods(),
        ]);

      options.me = me;
      options.addresses = addresses;
      options.shippingAddress = addresses.find((c) => c.type === 'shipping');

      if (paymentMethods.length && !state.paymentMethod) {
        options.paymentMethod = paymentMethods[0];
      }
    } catch (e) {
      setToken(null);
      return;
    }

    setState(options);
    return options.me;
  }

  async function registerAnonymous() {
    if (state.token) {
      return;
    }
    const { data } = await request({
      method: 'POST',
      path: '/1/auth/anonymous/register',
      body: {
        clientSessionId: localStorage.getItem('clientSessionId'),
      },
    });
    setState({ token: data.token });
    setToken(data.token);
  }

  function setToken(token) {
    if (token) {
      localStorage.setItem('because-token', token);
    } else {
      localStorage.removeItem('because-token');
    }
    setState({
      token,
    });
  }

  function setPaymentMethod(paymentMethod) {
    // tmp paymentMethod not safed yet (keeping it sessionStorage not have it stay around)
    sessionStorage.setItem(
      'paymentMethod',
      JSON.stringify({
        id: paymentMethod.id,
        card: {
          brand: paymentMethod.card.brand,
          last4: paymentMethod.card.last4,
        },
        zipCode:
          paymentMethod.zipCode ||
          paymentMethod.billing_details?.address?.postal_code,
      })
    );
    setState({
      paymentMethod,
    });
  }

  useEffect(() => {
    if (state.token) {
      boot();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...state,
        boot,
        setToken,
        registerAnonymous,
        setPaymentMethod,
        setHasCart: (hasCart) => setState({ hasCart }),
      }}>
      {children}
    </UserContext.Provider>
  );
};
