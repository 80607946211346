import React, { useContext, useEffect, useRef } from 'react';
import './styles.less';
import { IconCart } from '../Icons';
import { CartContext } from 'contexts/cart';
import { SheetsContext } from 'contexts/sheets';

export const ButtonCart = ({}) => {
  const cart = useContext(CartContext);
  const sheets = useContext(SheetsContext);

  const count = cart.products.reduce((acc, cur) => acc + cur.quantity, 0);

  return (
    <>
      <div className="button-cart" onClick={() => sheets.showCart()}>
        <IconCart />
        {count > 0 && <span className="button-cart__count">{count}</span>}
      </div>
    </>
  );
};
