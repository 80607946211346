const {
  APP_NAME,
  APP_URL,
  APP_SUPPORT_EMAIL,
  SENTRY_DSN,
  ENV_NAME,
  GOOGLE_API_KEY,
  API_CLIENT_ID,
  STRIPE_PUBLIC_KEY,
  INSIGHTS_API_URL,
} = window.__ENV__ || {};

const params = new URLSearchParams(window.location.search);

const API_URL = params.get('prod')
  ? 'https://api.because.world'
  : window.__ENV__.API_URL;

export {
  API_URL,
  APP_NAME,
  APP_URL,
  APP_SUPPORT_EMAIL,
  SENTRY_DSN,
  ENV_NAME,
  GOOGLE_API_KEY,
  API_CLIENT_ID,
  STRIPE_PUBLIC_KEY,
  INSIGHTS_API_URL,
};
