import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './container.less';

const transition = {
  ease: 'easeOut',
  duration: 0.15,
};

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? '100vw' : '-10vw',
      opacity: 0,
    };
  },
  animate: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '100vw' : '-10vw',
      opacity: 0,
    };
  },
};

export const Container = ({ pageState, page, ...props }) => {
  return (
    <AnimatePresence
      exitBeforeEnter={true}
      initial={false}
      custom={pageState.direction}>
      <motion.div
        key={`${pageState.path}-${pageState.key}`}
        initial="enter"
        animate="animate"
        exit="exit"
        custom={pageState.direction}
        variants={variants}
        transition={transition}
        className="sheet-wrapper">
        <page.Component
          key={pageState.key}
          pagePath={pageState.path}
          {...props}
          {...page.props}
          {...pageState.props}
        />
      </motion.div>
    </AnimatePresence>
  );
};
