import React, { useEffect, useContext, useRef, useState } from 'react';
import './screen.less';
import { AdaptiveBackground } from 'components/AdaptiveBackground';

export function Screen({ children }) {
  const [isReady, setReady] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // waiting for containerRef
    setReady(true);
  }, []);

  return (
    <div className="screen">
      <div ref={containerRef} className="screen__container">
        {isReady && (
          <>
            <AdaptiveBackground containerRef={containerRef} />
            <div id="screen-content">{children}</div>
          </>
        )}
      </div>
    </div>
  );
}
