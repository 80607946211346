import * as React from 'react';

export const SheetContext = React.createContext(undefined);

export const useSheetContext = () => {
  const context = React.useContext(SheetContext);
  if (!context) {
    throw Error('Sheet context error');
  }
  return context;
};
