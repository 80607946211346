import React, { useContext, useState, useEffect, useRef } from 'react';
import { VideoContext } from 'contexts/video';
import { VideoTimeContext } from 'contexts/video-time';
import { AdaptiveVideo } from 'components/Video';
import { useDebounce } from 'utils/hooks';

export const Video = ({ ...props }) => {
  const { setVideoRef } = useContext(VideoContext);
  const { setCurrentTime } = useContext(VideoTimeContext);
  const [time, setTime] = useState(0);
  const debouncedTime = useDebounce(time, 30);

  useEffect(() => {
    setCurrentTime(debouncedTime);
  }, [debouncedTime]);

  const videoRef = useRef(null);

  useEffect(() => {
    setVideoRef(videoRef);
    return () => {
      setVideoRef(null);
    };
  }, [videoRef]);

  const updateCurrentTime = (e) => {
    setTime(e.target.currentTime);
  };

  return (
    <AdaptiveVideo ref={videoRef} {...props} onTimeUpdate={updateCurrentTime} />
  );
};
