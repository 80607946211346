import React from 'react';
import './styles.less';

export const Prompt = ({ title, description, btnText = 'OK', onClose }) => {
  return (
    <div className="prompt" onClick={() => onClose()}>
      <div className="prompt__container">
        <h2>{title}</h2>
        <p>{description}</p>
        <div className="divider" />
        <div onClick={onClose} className="button-wrapper">
          <button>{btnText}</button>
        </div>
      </div>
    </div>
  );
};
