import { API_URL } from 'utils/env';
import { ApiError, ApiParseError } from './errors';
import { localStorage } from '../storage';

export default async function request(options) {
  const { method = 'GET', path, files, params, domain } = options;
  let { body } = options;

  const token =
    typeof options.token !== 'undefined'
      ? options.token
      : localStorage.getItem('because-token');

  const headers = Object.assign(
    {
      Accept: 'application/json',
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
    },
    options.headers
  );

  const url = new URL(path, domain || API_URL);
  url.search = new URLSearchParams(params);

  if (files) {
    const data = new FormData();
    files.forEach((file) => {
      data.append('file', file);
    });
    for (let [key, value] of Object.entries(body)) {
      data.append(key, value);
    }
    body = data;
  } else if (!(body instanceof FormData)) {
    body = JSON.stringify(body);
    headers['Content-Type'] = 'application/json';
  }

  const res = await fetch(url, {
    method,
    headers,
    body,
  });

  if (res.status === 204) {
    return;
  } else if (!res.ok) {
    let message, status, details;
    try {
      const data = await res.clone().json();
      message = data.error.message;
      status = data.error.status;
      details = data.error.details || data.error.soldOutProducts;
    } catch (err) {
      message = await res.clone().text();
    }

    window.dispatchEvent(
      new CustomEvent('requestError', {
        detail: {
          status: res.status,
          message,
        },
      })
    );

    throw new ApiError(
      message || res.statusText,
      status || res.status,
      details
    );
  }

  try {
    const contentType = res.headers.get('Content-Type').split(';')[0];
    if (contentType === 'application/json') {
      return await res.json();
    } else {
      return await res.text();
    }
  } catch (err) {
    throw new ApiParseError();
  }
}
