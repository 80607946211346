import React from 'react';
import { Component } from 'helpers';
import Hls from 'hls.js';
import { Loader } from '../Loader';
import staticPoster from 'assets/static.gif';

import './stream.less';
import { SENTRY_DSN } from '../../utils/env';

const IS_IOS = /iPhone|iPad|iPod/.test(navigator.platform);

export default class VideoStream extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      error: null,
      buffering: false,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { video } = this.props;
    if (video) {
      this.loadStream(video.playbackUrl);
    }
    this.ref.current.addEventListener('ended', this.onPlaybackEnded);
    this.ref.current.addEventListener('waiting', this.onWaiting);
    this.ref.current.addEventListener('playing', this.onPlaying);

    if (this.props.autoPlay) {
      this.ref.current.addEventListener('loadedmetadata', () => {
        this.ref.current.play();
      });
    }
  }

  componentDidUpdate(lastProps, lastState) {
    const status = this.props.video?.status;
    const lastStatus = lastProps.video?.status;
    const playbackUrl = this.props.video?.playbackUrl;
    const lastPlaybackUrl = lastProps.video?.playbackUrl;
    const { isActive } = this.state;
    if (playbackUrl && playbackUrl !== lastPlaybackUrl) {
      this.setState(
        {
          isActive: false,
        },
        () => {
          this.loadStream(playbackUrl);
        }
      );
    }
    if (status !== lastStatus) {
      // Attempt to load the live stream again if the status changes
      if (status === 'live' && playbackUrl) {
        this.loadStream(playbackUrl);
      }
    }
    if (isActive !== lastState.isActive) {
      if (isActive) {
        this.startStream();
      }
    }
  }

  componentWillUnmount() {
    this.unloadStream();
    this.ref.current.removeEventListener('ended', this.onPlaybackEnded);
    this.ref.current.removeEventListener('waiting', this.onWaiting);
    this.ref.current.removeEventListener('playing', this.onPlaying);
  }

  loadStream(playbackUrl) {
    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.on(Hls.Events.ERROR, this.onHlsError);
      this.hls.on(Hls.Events.LEVEL_UPDATED, this.onHlsLevelUpdated);
      this.hls.on(Hls.Events.LEVEL_LOADED, this.onHlsLevelLoaded);
      this.hls.loadSource(playbackUrl);
    } else if (IS_IOS) {
      this.setState({
        isActive: true,
      });
    }
  }

  onWaiting = () => {
    this.setState({
      buffering: true,
    });
  };

  onPlaying = () => {
    this.setState({
      buffering: false,
    });
  };

  unloadStream() {
    if (this.hls) {
      this.hls.detachMedia();
      this.hls.off(Hls.Events.ERROR, this.onHlsError);
      this.ref.current.pause();
      this.hls = null;
    }
  }

  startStream() {
    if (this.hls) {
      this.hls.attachMedia(this.ref.current);
      // Note: iOS safari cannot autoplay, as it requires
      // user interaction.
      if (this.props.autoPlay) {
        this.play();
      }
    }
  }

  async play() {
    try {
      this.ref.current.play();
      if (this.props.onStart) {
        this.props.onStart();
      }
    } catch (err) {
      // DOMException may throw if not interacted with document yet
    }
  }

  onHlsLevelUpdated = (name, { details }) => {
    this.setLevel(details);
  };

  onHlsLevelLoaded = (name, { details }) => {
    this.setLevel(details);
  };

  onHlsError = (name, { fatal, ...rest }) => {
    if (fatal) {
      const { message, type } = this.getErrorDetails(rest);
      if (type === 'live_stream_inactive' || type === 'failed_request') {
        this.setLive(false);
      } else {
        this.setState({
          error: new Error(message || 'Stream error'),
        });
      }
    }
  };

  getErrorDetails({ details, networkDetails }) {
    const { video } = this.props;
    if (video?.source === 'mux') {
      const data = JSON.parse(networkDetails.response);
      const [message] = data.error.messages;
      return {
        message,
        type: data.error.type,
      };
    } else {
      return {
        message: details,
      };
    }
  }

  onPlaybackEnded = () => {
    const el = this.ref.current;
    if (el.webkitExitFullScreen) {
      el.webkitExitFullScreen();
    } else if (el.exitFullScreen) {
      el.exitFullScreen();
    }
    if (this.props.onEnd) {
      setTimeout(() => {
        this.props.onEnd();
      }, 50);
    }
  };

  setLevel(details) {
    if (this.isLiveVideo()) {
      this.setLive(details.live);
    } else {
      this.setState({
        isActive: true,
      });
    }
  }

  setLive(live) {
    if (live && !this.state.isActive) {
      this.setState({
        isActive: live,
      });
    }
  }

  isLiveVideo() {
    const { videoType } = this.props.video;
    return videoType === 'live';
  }

  isLoading() {
    const { videoType, status } = this.props.video;
    return videoType === 'vod' && status === 'preparing';
  }

  render() {
    if (Hls.isSupported() || IS_IOS) {
      const { video } = this.props;
      const { isActive, error, buffering } = this.state;
      const isLiveVideo = this.isLiveVideo();

      return (
        <div {...this.getProps()}>
          {error && (
            <div className={this.getElementClass('error')}>{error.message}</div>
          )}
          {this.isLoading() ||
            (buffering && (
              <div className={this.getElementClass('buffering')}>
                <Loader />
              </div>
            ))}
          <video
            preload="auto"
            playsInline
            ref={this.ref}
            src={IS_IOS ? video.playbackUrl : null}
            controlsList="nodownload"
            disablePictureInPicture
            poster={isLiveVideo && !isActive ? staticPoster : null}
            controls={false}
            className={this.getElementClass(
              'video',
              !isActive ? 'disabled' : null
            )}
          />
        </div>
      );
    } else {
      return 'Live stream not supported';
    }
  }
}
