import React from 'react';
import './button.less';
import classNames from 'classnames';
import { Loader } from '../Loader';

export const Button = ({
  children,
  primary,
  icon,
  fluid,
  className,
  rounded,
  disabled,
  control,
  loading,
  ...props
}) => {
  return (
    <button
      disabled={disabled || loading}
      className={classNames('button', className, {
        fluid,
        primary,
        icon,
        rounded,
        disabled,
        loading,
        control,
      })}
      {...props}>
      {loading ? <Loader small dark /> : props.content || children}
    </button>
  );
};
