import React, { useContext, useMemo } from 'react';
import './timeline.less';
import { VideoTimeContext } from 'contexts/video-time';

export const Timeline = ({ chapters }) => {
  const { currentTime } = useContext(VideoTimeContext);

  const parseChapters = useMemo(() => {
    let currentStart = 0;
    const list = [];
    for (let i = 0; i < chapters.length; i++) {
      if (chapters[i].end > currentStart) {
        list.push({
          ...chapters[i],
          start: currentStart,
        });
        currentStart = chapters[i].end;
      }
    }
    return list;
  }, [chapters]);

  return (
    <div className="timeline">
      {parseChapters.map((chapter, index) => {
        const progress =
          chapter.start > currentTime
            ? 0
            : Math.min(
                1,
                (currentTime - chapter.start) / (chapter.end - chapter.start)
              );

        return (
          <div className="timeline__chapter" key={chapter._id}>
            <div
              style={{ width: `${progress * 100}%` }}
              className="timeline__progress"
            />
          </div>
        );
      })}
    </div>
  );
};
