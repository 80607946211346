import React, { useContext, useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';

import { UserContext } from 'contexts/user';
import { Container } from '../Container';
import { Input } from '../Input';
import { Button } from '../Button';
import { Prompt } from '../Prompt';
import { request } from 'utils/api';

import './account.less';

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

function transformUser(me = {}) {
  if (!me.email) {
    return {};
  }
  return me;
}

export const Account = ({ onNavigate, containerRef }) => {
  const user = useContext(UserContext);
  const me = transformUser(user.me);

  const [state, setState] = useLocalState({
    loading: false,
    error: null,
  });

  const [form, setForm] = useLocalState({
    email: me.email || '',
    firstName: me.firstName || '',
    lastName: me.lastName || '',
  });

  const onSave = async () => {
    setState({ loading: true });
    try {
      await user.registerAnonymous();
      await request({
        method: 'PATCH',
        path: `/1/users/me`,
        body: form,
      });
      await user.boot();
      onNavigate('checkout');
    } catch (e) {
      setState({ error: e });
    }
    setState({ loading: false });
  };

  const isDisabled = !form.email || !form.firstName || !form.lastName;

  return (
    <div className="checkout-account">
      {state.error &&
        ReactDOM.createPortal(
          <Prompt
            title="Failed to save"
            description={state.error.message}
            onClose={() => {
              setState({ error: null });
            }}
          />,
          containerRef
        )}
      <Container>
        <Input
          label="Firstname"
          name="firstName"
          fluid
          required
          value={form.firstName}
          onChange={(e, { value, name }) => setForm({ [name]: value })}
        />
        <Input
          label="Lastname"
          name="lastName"
          fluid
          required
          value={form.lastName}
          onChange={(e, { value, name }) => setForm({ [name]: value })}
        />
        <Input
          label="Email"
          required
          fluid
          value={form.email}
          name="email"
          onChange={(e, { value, name }) => setForm({ [name]: value })}
        />
        {/*
        <Input
          label="Password"
          required
          value={form.password}
          name="password"
          onChange={(e, { value, name }) => setForm({ [name]: value })}
        />
        */}
      </Container>
      <Container>
        <Button
          disabled={isDisabled}
          loading={state.loading}
          fluid
          primary
          onClick={onSave}>
          Save
        </Button>
      </Container>
    </div>
  );
};
