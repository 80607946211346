import React from 'react';
import PropTypes from 'prop-types';
import { SemanticElement } from 'helpers';

import './spacer.less';

export default class Spacer extends SemanticElement {
  static className = 'spacer';

  render() {
    return <div {...this.getProps()} />;
  }
}

Spacer.propTypes = {
  size: PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl']),
};

Spacer.defaultProps = {
  size: 'm',
};
