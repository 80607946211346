import React from 'react';
import './event-banner.less';

import { screen, Component } from 'helpers';

import logoInverted from 'assets/logo-inverted.svg';
import { urlForImage } from 'utils/uploads';
import { request } from 'utils/api';
import App from 'screens/App';

import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from 'utils/env';
import { Elements } from '@stripe/react-stripe-js';

function unescape(data) {
  return data
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
}

@screen
export default class Landing extends Component {
  static title = 'BECAUSE';

  state = {};

  async fetchData() {
    if (window.BECAUSE_SHALLOW_ITEM) {
      this.setState({
        item: JSON.parse(unescape(JSON.stringify(window.BECAUSE_SHALLOW_ITEM))),
      });
      return;
    }

    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/events/${this.props.match.params.id}/shallow`,
        token: false,
      });
      this.setState({ item: data });
    } catch (error) {
      this.setState({ error });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    if (this.state.error) {
      return this.renderDesktopAndIOS();
    }

    const data = this.state.item;
    if (!data) return null;

    window.stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
    return (
      <Elements stripe={window.stripePromise}>
        <App shallowEvent={data} />
      </Elements>
    );
  }

  renderDesktopAndIOS() {
    const { item: data, error } = this.state;

    return (
      <div className="event-banner">
        <div className="container only-desktop">
          <div
            style={{
              position: 'absolute',
              zIndex: '2',
              top: '2vh',
              left: '2vw',
              bottom: '2vh',
              right: '2vw',
              margin: '0',
              backgroundColor: '#000',
            }}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img
                className="logo"
                style={{
                  maxWidth: '600px',
                }}
                src={logoInverted}
              />
              <p style={{ fontSize: '18px' }}>
                Because is only available on iOS
              </p>
            </div>
          </div>
        </div>
        <div className="only-mobile">
          <div className="banner">
            <div className="banner__text">
              Shop Now by tapping 'view' in the banner at the top of the
              website.
            </div>
            <div className="banner__icon">👆</div>
          </div>
          <div className="container">
            {error && <div className="message error">{error.message}</div>}
            {data && (
              <div className="event-item">
                <img
                  src={urlForImage(data.appClipCardImage || data.coverImage, {
                    height: 190 * 3,
                  })}
                />

                <div className="creator">
                  {data.creatorAccount.profileImage && (
                    <img
                      src={urlForImage(data.creatorAccount.profileImage, {
                        height: 120 * 3,
                      })}
                    />
                  )}
                  <div>{data.creatorAccount.name}</div>
                </div>
                <h2>{data.name}</h2>
                <p>{data.description}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
