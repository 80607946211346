import React, { useContext, useEffect, useState } from 'react';

import { Route } from 'react-router-dom';
import { Event } from './Event';
import { Notification } from 'components/Notification';
import { useLocation, useHistory, Switch } from 'react-router-dom';
import { CartProvider } from 'contexts/cart';
import { UserProvider } from 'contexts/user';
import { VideoProvider } from 'contexts/video';
import { ProductsProvider } from 'contexts/products';
import { TrackingProvider } from 'contexts/tracking';
import { VideoTimeProvider } from 'contexts/video-time';

import { NotificationProvider } from 'contexts/notification';
import { Screen } from './Screen';
import { TrackingContext } from 'contexts/tracking';
import { SheetsProvider } from 'contexts/sheets';
import { AnimatePresence } from 'framer-motion';
import { request } from 'utils/api';

import './styles.less';

const Wrapper = ({ children, shallowEvent, event }) => {
  const tracking = useContext(TrackingContext);
  useEffect(() => {
    if (tracking) {
      const timer = new Date();

      // Ensure that event is set
      setTimeout(() => {
        tracking.addEvent('session-started', {
          eventId: shallowEvent.id,
        });
      }, 0);

      return () => {
        tracking.addEvent('session-ended', {
          secondsSinceEntry: (Date.now() - timer) / 1000,
        });
      };
    }
  }, [tracking]);

  useEffect(() => {
    if (event) {
      tracking.setEvent(event);
    }
  }, [event]);

  return children;
};

function ProviderWrappers({ event, children }) {
  if (event) {
    return (
      <VideoTimeProvider>
        <VideoProvider event={event}>
          <ProductsProvider event={event}>
            <SheetsProvider>{children}</SheetsProvider>
          </ProductsProvider>
        </VideoProvider>
      </VideoTimeProvider>
    );
  }
  return children;
}

function fetchEvent(eventId) {
  return request({
    method: 'GET',
    path: `/1/events/${eventId}`,
    token: false,
  });
}

const App = ({ shallowEvent }) => {
  const location = useLocation();
  const history = useHistory();

  const [event, setEvent] = useState();

  useEffect(() => {
    if (shallowEvent) {
      fetchEvent(shallowEvent.id).then(({ data }) => {
        setEvent(data);
      });
    }
  }, [shallowEvent.id]);

  return (
    <TrackingProvider>
      <Wrapper shallowEvent={shallowEvent} event={event}>
        <UserProvider>
          <CartProvider>
            <NotificationProvider>
              <ProviderWrappers event={event} shallowEvent={shallowEvent}>
                <Screen>
                  <Notification />
                  <AnimatePresence
                    exitBeforeEnter={true}
                    initial={false}
                    // the exit animation needs the updated history to know if it's a push or pop
                    custom={{ action: history.action }}>
                    <Switch location={location} key={location.pathname}>
                      <Route exact path="/event/:id">
                        <Event event={event} shallowEvent={shallowEvent} />
                      </Route>
                    </Switch>
                  </AnimatePresence>
                </Screen>
              </ProviderWrappers>
            </NotificationProvider>
          </CartProvider>
        </UserProvider>
      </Wrapper>
    </TrackingProvider>
  );
};

export default App;
