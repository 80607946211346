import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Container } from 'components';
import { screen, Component } from 'helpers';

import './terms.less';

import TERMS from './terms.md';
import PRIVACY from './privacy.md';

@screen
export default class Terms extends Component {
  static title = 'TERMS AND PRIVACY';

  render() {
    return (
      <div className="terms">
        <Container>
          <br />
          <br />
          <ReactMarkdown children={TERMS} />
          <br />
          <br />
          <ReactMarkdown children={PRIVACY} />
          <br />
          <br />
        </Container>
      </div>
    );
  }
}
