import React from 'react';
import { Container } from '../Container';
import { Markdown } from '../Markdown';

import './styles.less';

export const ProductDetails = ({ product }) => {
  return (
    <div className="product-details">
      <Container>
        <Markdown>{product.details}</Markdown>
      </Container>
    </div>
  );
};
