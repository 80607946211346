export const sizes = [
  'XXXS',
  'XXXS/XXS',
  'XXS/XXXS',
  'XXS',
  'XXS/XS',
  'XS/XXS',
  'XS',
  'XS/S',
  'S/SX',
  'S',
  'S/M',
  'M/S',
  'M',
  'M/L',
  'L/M',
  'L',
  'XL/L',
  'L/XL',
  'XL',
  'XL/XXL',
  'XXL/XL',
  'XXL',
  'XXXL/XXL',
  'XXL/XXXL',
  'XXXL',
  '00',
  '0',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
];

export const sortSizes = (a, b) => {
  const sizes = [
    'XXXS',
    'XXXS/XXS',
    'XXS/XXXS',
    'XXS',
    'XXS/XS',
    'XS/XXS',
    'XS',
    'XS/S',
    'S/SX',
    'S',
    'S/M',
    'M/S',
    'M',
    'M/L',
    'L/M',
    'L',
    'XL/L',
    'L/XL',
    'XL',
    'XL/XXL',
    'XXL/XL',
    'XXL',
    'XXXL/XXL',
    'XXL/XXXL',
    'XXXL',
    '00',
    '0',
    '2',
    '4',
    '6',
    '8',
    '10',
    '12',
    '14',
    '16',
  ];

  const aIdx = sizes.indexOf(a.toUpperCase());
  const bIdx = sizes.indexOf(b.toUpperCase());

  if (aIdx < 0) {
    if (!isNaN(a) && !isNaN(b)) {
      return Number(a) - Number(b);
    }
    return !isNaN(a) ? -1 : 1;
  }
  if (bIdx < 0) {
    if (!isNaN(a) && !isNaN(b)) {
      return Number(a) - Number(b);
    }
    return !isNaN(b) ? -1 : 1;
  }
  return aIdx - bIdx;
};
