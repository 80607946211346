import React, { useContext, useEffect, useState } from 'react';

import { formatUsd } from 'utils/formatting';
import './styles.less';
import { CartContext } from 'contexts/cart';
import { Loader } from '../Loader';
import { Markdown } from '../Markdown';

export const CartTotal = ({ onError }) => {
  const cart = useContext(CartContext);

  useEffect(() => {
    if (cart.error) {
      onError(cart.error);
    }
  }, [cart.error]);

  if (cart.loading || cart.error) {
    return (
      <div className="cart-total loading">
        <Loader dark small />
      </div>
    );
  }

  const isPreOrder = cart?.products?.some(
    (item) => item?.product?.preOrder?.message && item?.product?.compareAtPrice
  );

  return (
    <div className="cart-total">
      <div className="cart-total__item">
        <span className="label">Delivery</span>
        <span className="value">FREE</span>
      </div>
      {cart.discountAmount > 0 && (
        <div className="cart-total__item">
          <span className="label">Discount</span>
          <span className="value">-{formatUsd(cart.discountAmount / 100)}</span>
        </div>
      )}
      <div className="cart-total__item">
        <span className="label">Tax</span>
        <span className="value">
          {cart.taxAmount
            ? formatUsd(cart.taxAmount / 100)
            : 'Calculated at checkout'}
        </span>
      </div>
      <div className="cart-total__item total">
        <span className="label">{cart.amount ? 'Total' : 'Subtotal'}</span>
        <span className="value">
          {formatUsd((cart.amount || cart.preTaxAmount) / 100)}
        </span>
      </div>
      {/* TODO: Can we have some items that are pre-order and others that are
      not? If so we will need to update this to handle that case. */}
      {isPreOrder && (
        <div className="desposit">
          <div className="cart-total__item primary">
            <span className="label">Est. Pay Today</span>
            <span className="value">
              {formatUsd((cart.amount || cart.preTaxAmount) / 100)}
            </span>
          </div>
          <div className="cart-total__item secondary">
            <span className="label">Est. Remaining Balance</span>
            <span className="value">
              {formatUsd((cart.amount || cart.preTaxAmount) / 100)}
            </span>
          </div>
          <Markdown>{cart.checkoutMessage}</Markdown>
        </div>
      )}
    </div>
  );
};
