import React from 'react';
import { ButtonClose } from '../ButtonClose';
import { ButtonBack } from '../ButtonBack';
import './header.less';

export const SheetHeader = ({
  onBack,
  onClose,
  title,
  hasBack = false,
  hasClose = true,
}) => {
  return (
    <div className="sheet-header">
      <div className="sheet-header__indicator" />
      <div className="sheet-header__container">
        {hasBack ? (
          <div className="spacer left">
            <ButtonBack onClick={() => onBack()} />
          </div>
        ) : (
          <div className="spacer left" />
        )}
        {title && <h2>{title}</h2>}
        <div className="spacer right">
          {hasClose && onClose && <ButtonClose onClick={() => onClose()} />}
        </div>
      </div>
    </div>
  );
};
