import React from 'react';
import './container.less';

export const Container = ({ style, children, className }) => {
  return (
    <div
      style={style}
      className={['container', className].filter(Boolean).join(' ')}>
      {children}
    </div>
  );
};
