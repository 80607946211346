import React from 'react';

import './product.less';
import { ProductOverview } from '../../ProductOverview';

export const SheetProduct = ({ ...props }) => {
  return (
    <div className="product-sheet">
      <ProductOverview {...props} header={false} />
    </div>
  );
};
