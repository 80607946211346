import React, { useReducer, createContext, useMemo, useEffect } from 'react';

export const VideoTimeContext = createContext();



function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}


export const VideoTimeProvider = ({ children, event }) => {
  const [state, setState] = useLocalState({
    setCurrentTime: () => {},
  });

  return (
    <VideoTimeContext.Provider
      value={{
        ...state,
        setCurrentTime: (time) => {
          setState({ currentTime: time });
        },
      }}>
      {children}
    </VideoTimeContext.Provider>
  );
};
