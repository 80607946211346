import React, { useReducer, createContext, useEffect } from 'react';

export const NotificationContext = createContext();

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

export const NotificationProvider = ({ children }) => {
  const [state, setState] = useLocalState({
    notification: null,
  });

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        add: (notification) => {
          setState({ notification });
          setTimeout(() => {
            setState({ notification: null });
          }, 2500);
        },
        remove: () => {
          setState({ notification: null });
        },
      }}>
      {children}
    </NotificationContext.Provider>
  );
};
