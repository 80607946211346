import classNames from 'classnames';
import React from 'react';
import './styles.less';

export const Loader = ({ dark, small }) => {
  return (
    <div className={classNames('loader', { dark, small })}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
