import React from 'react';

export const Options = ({
  name,
  options,
  onChange,
  onClose,
  ignoreDisabled,
}) => {
  return (
    <div className="select-overlay__options">
      {options.map((option) => {
        return (
          <div
            key={option.value}
            onClick={() => {
              if (option.disabled && !ignoreDisabled) return;
              onChange({ name, value: option.value });
              onClose(false);
            }}
            className={[
              'select-overlay__option',
              option.disabled ? 'disabled' : '',
            ].join(' ')}>
            {option.label ? option.label : option.value}
          </div>
        );
      })}
    </div>
  );
};
