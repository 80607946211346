import React, { useContext, useEffect } from 'react';

import { CartContext } from 'contexts/cart';
import { TrackingContext } from 'contexts/tracking';
import { ProductCard } from '../../ProductCard';
import { Button } from '../../Button';

import './products.less';

export const SheetProducts = ({ event, onNavigate }) => {
  const cart = useContext(CartContext);
  const tracking = useContext(TrackingContext);
  const count = cart.products.reduce((acc, cur) => acc + cur.quantity, 0);

  // TODO: This is duplicated code. It meeds to be refactored.
  const productIdsArray = (
    event.streams.find((c) => c.type === 'main')?.products || []
  ).map((c) => c.productId);

  const productIdsSet = new Set(productIdsArray);
  const productIds = [...productIdsSet];

  const products = productIds
    .map((productId) => {
      return event.products.find(
        (product) =>
          product.subProducts.find((c) => c.id === productId) ||
          product.id === productId
      );
    })
    .filter(Boolean);

  useEffect(() => {
    tracking.addEvent('product-list-viewed');
  }, []);

  return (
    <div className="products-sheet">
      <h2>Products</h2>

      {count.length && (
        <Button fluid primary onClick={() => share(event)}>
          Review Cart
        </Button>
      )}

      <div className="products">
        {products.map((product) => (
          <div
            key={product.id}
            onClick={() => onNavigate('root/product', product.id, { product })}>
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};
