import React, { useContext, useEffect, useMemo, useState } from 'react';
import { VideoContext } from 'contexts/video';
import { VideoTimeContext } from 'contexts/video-time';

export const Seekbar = ({ chapters }) => {
  const { jumpTo, ended } = useContext(VideoContext);
  const { currentTime } = useContext(VideoTimeContext);

  const [currentChapter, setCurrentChapter] = useState(0);

  const parseChapters = useMemo(() => {
    let currentStart = 0;
    const list = [];
    for (let i = 0; i < chapters.length; i++) {
      if (chapters[i].end > currentStart) {
        list.push({
          ...chapters[i],
          start: currentStart,
        });
        currentStart = chapters[i].end;
      }
    }
    return list;
  }, [chapters]);

  useEffect(() => {
    const current = parseChapters.findIndex(
      (chapter) => chapter.start <= currentTime && chapter.end > currentTime
    );
    setCurrentChapter(current);
  }, [currentTime, parseChapters]);

  function onNextChapter() {
    const nextChapter = parseChapters[currentChapter + 1];
    if (!nextChapter || ended) return;
    jumpTo(nextChapter.start);
    setCurrentChapter(currentChapter + 1);
  }

  function onPrevChapter() {
    const prevChapter = parseChapters[currentChapter - 1];
    if (!prevChapter || ended) return;
    jumpTo(prevChapter.start);
    setCurrentChapter(currentChapter - 1);
  }

  return (
    <>
      <div onClick={onPrevChapter} className="player__goback" />
      <div onClick={onNextChapter} className="player__goforward" />
    </>
  );
};
