import React, { useContext } from 'react';
import './styles.less';
import { NotificationContext } from 'contexts/notification';

export const Notification = ({ title }) => {
  const { notification, remove } = useContext(NotificationContext);
  if (!notification) return null;

  return (
    <div className="notification" onClick={remove}>
      <h2>{notification.title}</h2>
    </div>
  );
};
