import React, { useContext, useEffect } from 'react';

import { CartContext } from 'contexts/cart';
import { TrackingContext } from 'contexts/tracking';
import { ProductCard, ProductDetails, Sheet } from 'components';

import { SheetProduct } from 'components/Player/Sheets';

import { Markdown } from 'components/Markdown';

import './imageEvent.less';

export default function ImageEvent({ event, showFloatingCart, onNavigate }) {
  const cart = useContext(CartContext);
  const tracking = useContext(TrackingContext);

  const [selectedProduct, setSelectedProduct] = React.useState(null);

  useEffect(() => {
    tracking.addEvent('product-list-viewed');
  }, []);

  return (
    <div className="imageEvent">
      <Markdown className="imageEvent__description">
        {event.description}
      </Markdown>

      <div className="products">
        {event.products.map((product) => (
          <div key={product.id} onClick={() => setSelectedProduct(product)}>
            <ProductCard product={product} />
          </div>
        ))}
      </div>
      <Sheet
        open={!!selectedProduct}
        onFullScreen={() => {}}
        fullscreen={true}
        key={selectedProduct?.id}
        root="product"
        onClose={() => {
          setSelectedProduct(null);
        }}
        event={event}
        zIndex={100}
        product={selectedProduct}
        pages={[
          {
            id: 'product',
            Component: SheetProduct,
            header: {
              hasBack: false,
            },
            children: [
              {
                header: {
                  title: 'Product Details',
                  hasBack: true,
                  hasClose: false,
                },

                parent: 'product',
                id: 'details',
                Component: ProductDetails,
              },
            ],
          },
        ]}
      />
    </div>
  );
}
