import React from 'react';

import './styles.less';
import { Button } from '../Button';
import { IconArrowDown } from '../Icons';
import { Options } from './Options';

import { Sheet } from '../Sheet';

export const Select = ({
  label,
  name,
  value,
  options,
  ignoreDisabled,
  onChange = () => {},
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="select">
      <Button fluid onClick={() => setOpen(true)}>
        {value ? (
          <>
            {label}:
            {options.find((option) => option.value === value)?.label || value}
          </>
        ) : (
          <>Select {label}</>
        )}
        <IconArrowDown />
      </Button>

      <Sheet
        open={open}
        root="select"
        fullscreen
        zIndex={110}
        onClose={() => setOpen(false)}
        pages={[
          {
            header: {
              title: label,
            },
            id: 'select',
            props: {
              options,
              name,
              ignoreDisabled,
            },
            Component: Options,
          },
        ]}
        onChange={onChange}
      />
    </div>
  );
};
