export function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatUsd = (value) => formatter.format(value);

export function formatAddress(address) {
  const { line1, line2, city, state, postalCode } = address;
  const parts = [
    [line1, line2].filter(Boolean).join(', '),
    [city, postalCode, state].filter(Boolean).join(', '),
  ];
  return parts.filter((p) => p);
}
