import React from 'react';
import { Container, Button } from 'components';
import logoInverted from 'assets/logo-inverted.svg';
import { AdaptiveVideo } from 'components/Video';
import UnlockForm from './UnlockForm';

import { screen, Component } from 'helpers';
import './styles.less';

const teaserVideo = {
  videoType: 'vod',
  source: 'mux',
  status: 'ready',
  externalId: 'zMHYWO5XiF5bhVyQu01oO02TzMhnZl9X017ZQn02vE8dyZc',
  playbackUrl:
    'https://stream.mux.com/R4XtUEX00pn1ArRxQyGstTFbcmEOpluyPp5QUSxfp6fI.m3u8',
  originalVideoURL:
    'https://storage.googleapis.com/because-production-uploads/because%20sizzle%205.m4v',
  createdAt: '2020-12-09T19:50:24.405Z',
  updatedAt: '2020-12-09T19:50:24.405Z',
};

const productVideo = {
  videoType: 'vod',
  source: 'mux',
  status: 'preparing',
  externalId: 'Sgz01kAHF13CnY8Zi01gwTp1jdrKUqhUYZVVU86mFJnNw',
  playbackUrl:
    'https://stream.mux.com/iHvnjyDkPTdH5Z01EnSUT800GLSxnicNdwcYoFhdftjGg.m3u8?beta_serve_pdt=true',
  originalVideoURL:
    'https://storage.googleapis.com/download/storage/v1/b/because-production-uploads/o/be2ff7296ebd012fc784f2b2dbc620004a8248d7b8430ae45ad33d6e508140c7.mov?generation=1615944672393886&alt=media',
  createdAt: '2021-03-17T01:31:13.121Z',
  updatedAt: '2021-03-17T01:31:13.121Z',
  id: '60515be1f6555213b7b839dd',
};

const unlockCodes = [
  'bemoji',
  'todd',
  'alexandra',
  'justine',
  'pierre',
  'plummer',
  'kdc',
  'marc',
  'katharina',
  'kkw',
  'oken',
  'nelle',
  'gail',
  'mindy',
  'sjp',
  'alex',
  'ww',
];

const unlockCodesProduct = ['product', 'plummer2', 'ipsy', 'luxury'];

const allUnlockCodes = unlockCodes.concat(unlockCodesProduct);

@screen
export default class LandingScreen extends Component {
  static layout = 'landing';
  static title = 'BECAUSE';

  constructor(props) {
    super(props);
    const code = window.localStorage.getItem('landingEnteredCode');
    this.state = {
      state: 'loading',
      invalidCode: false,
      code: code,
      unlocked: allUnlockCodes.includes(code),
    };
    this.teaserVideoRef = React.createRef();
    this.productVideoRef = React.createRef();
    this.unlockRef = React.createRef();
  }

  showUnlockForm() {
    this.setState({ state: 'form' });
  }

  componentDidMount() {
    this.setState({ state: 'start' });
  }

  unlock(rawCode) {
    this.setState({ invalidCode: false });
    const code = rawCode ? rawCode.toLowerCase() : '';
    if (allUnlockCodes.includes(code)) {
      this.playVideo(code);
      window.localStorage.setItem('landingEnteredCode', code);
      gtag('event', 'unlock_success', {
        code,
      });
    } else {
      gtag('event', 'unlock_fail', {
        code,
      });
      this.setState({ state: 'form', invalidCode: true });
      this.unlockRef.current.select();
    }
  }

  playVideo(code) {
    this.setState({
      state: 'video',
      unlocked: true,
      code,
    });
    // Note: can't do this on a timeout as playing
    // the video has to be the response of a user
    // interaction.
    if (unlockCodesProduct.includes(code)) {
      this.productVideoRef.current.play();
    } else {
      this.teaserVideoRef.current.play();
    }
    gtag('event', 'video_start', { code });
  }

  renderVideo() {
    const { state, code } = this.state;
    const variety = unlockCodesProduct.includes(code) ? 'product' : 'teaser';
    return (
      <div
        style={{
          position: 'absolute',
          zIndex: '1',
          top: '2vh',
          left: '2vw',
          bottom: '2vh',
          right: '2vw',
          margin: '0',
          transition: 'opacity 500ms linear',
          opacity: state === 'video' ? 1 : 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <div style={{ display: variety === 'teaser' ? 'block' : 'none' }}>
          <AdaptiveVideo
            controls
            ref={this.teaserVideoRef}
            video={teaserVideo}
            onEnd={() => {
              gtag('event', 'video_end', { code });
              setTimeout(() => {
                this.setState({ state: 'start' });
              }, 900);
            }}
          />
        </div>
        <div style={{ display: variety === 'product' ? 'block' : 'none' }}>
          <AdaptiveVideo
            controls
            ref={this.productVideoRef}
            video={productVideo}
            onEnd={() => {
              gtag('event', 'video_end', { code });
              setTimeout(() => {
                this.setState({ state: 'start' });
              }, 900);
            }}
          />
        </div>
      </div>
    );
  }
  renderUnlockForm() {
    const { invalidCode } = this.state;
    return (
      <>
        {invalidCode && (
          <p style={{ fontSize: '18px' }}>Whoops, that's not quite right.</p>
        )}
        <UnlockForm
          ref={this.unlockRef}
          onUnlock={(code) => this.unlock(code)}
        />
      </>
    );
  }
  render() {
    const { state, unlocked } = this.state;

    return (
      <div className="landing">
        <Container>
          {this.renderVideo()}

          <div
            style={{
              position: 'absolute',
              zIndex: '2',
              top: '2vh',
              left: '2vw',
              bottom: '2vh',
              right: '2vw',
              margin: '0',
              backgroundColor: '#000',
              transition: 'opacity 500ms linear',

              opacity: state === 'start' || state === 'form' ? 1 : 0,
            }}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img
                className="logo"
                style={{
                  maxWidth: '600px',
                }}
                src={`${logoInverted}`}
              />
              <br />
              {state === 'form' && this.renderUnlockForm()}
              <div style={{ marginBottom: '50px' }}>
                {state === 'start' &&
                  (unlocked ? (
                    <Button
                      className="unlock-button"
                      content="REPLAY"
                      rounded
                      onClick={() => this.playVideo(this.state.code)}
                    />
                  ) : (
                    <Button
                      className="unlock-button"
                      content="Unlock"
                      rounded
                      onClick={() => this.showUnlockForm()}
                    />
                  ))}
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
