import React from 'react';

export default class UnlockForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enteredCode: '',
    };
    this.inputRef = React.createRef();
  }

  select() {
    this.inputRef.current.select();
  }

  render() {
    return (
      <form
        autoComplete="off"
        autoCapitalize="off"
        onSubmit={(e) => {
          e.preventDefault();
          this.props.onUnlock(this.state.enteredCode);
        }}>
        <div
          className="ui action input large"
          style={{ width: 'auto', marginTop: '10px' }}>
          <input
            ref={this.inputRef}
            type="text"
            placeholder="Enter Code"
            onChange={(e) => {
              this.setState({ enteredCode: e.target.value });
            }}
            style={{
              backgroundColor: '#111',
              border: '1px solid white',
              caretColor: 'white',
              borderRadius: '10em',
              color: '#FCFDFE',
              width: '160px',
              textAlign: 'center',
            }}
            autoFocus
          />
          <button
            className="ui icon button landing-button"
            style={{
              borderTopRightRadius: '10em',
              borderBottomRightRadius: '10em',
              paddingLeft: '1em',
              paddingRight: '1.5em',
              fontSize: '18px',
            }}>
            Unlock
          </button>
        </div>
      </form>
    );
  }
}
