import React from 'react';
import { Layout } from 'components/Layout';
import { Spacer } from 'components/Spacer';
import { request } from 'utils/api';

import { screen, Component } from 'helpers';
import logo from 'assets/logo-black.svg';

import { Button, Input } from 'components';
import { getToken, parseToken } from 'utils/token';

import '../auth.less';

@screen
export default class ResetPasswordScreen extends Component {
  static title = 'Reset Password';

  constructor(props) {
    super(props);
    const token = getToken(props);
    const parsedToken = token && parseToken(token);
    this.state = {
      token,
      jwt: parsedToken,
      loading: false,
      error: null,
      fields: {},
    };
  }

  setFields(name, value) {
    this.setState({
      touched: true,
      fields: {
        ...this.state.fields,
        [name]: value,
      },
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { token, fields } = this.state;
    try {
      this.setState({
        loading: true,
        error: null,
      });
      await request({
        method: 'POST',
        path: '/1/auth/set-password',
        token,
        body: fields,
      });

      this.setState({
        loading: false,
        success: true,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { jwt, error, fields, loading, success } = this.state;

    return (
      <div className="auth-screen">
        <Layout vertical center>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <img
              src={logo}
              width={126}
              height={23}
              className={this.getElementClass('logo')}
            />

            <Spacer size="s" />
            {!jwt && (
              <div className="message error">
                <div className="header">No valid token found</div>
                <p>
                  Please ensure you either click the email link in the email or
                  copy paste the link in full.
                </p>
              </div>
            )}
            {success && (
              <div className="message info">
                <div className="header">Your password has been changed!</div>
              </div>
            )}
            <Spacer size="s" />
            {jwt && !success && (
              <>
                <form onSubmit={this.onSubmit}>
                  {error && (
                    <div className="message error">{error.message}</div>
                  )}
                  {!error && <Spacer size="m" />}
                  <Input
                    placeholder="Enter New Password"
                    label="Password"
                    autoComplete="new-password"
                    name="password"
                    type="password"
                    fluid
                    value={fields.password || ''}
                    onChange={(e, { name, value }) =>
                      this.setFields(name, value)
                    }
                    error={error?.hasField?.('password')}
                  />
                  <Spacer size="m" />
                  <Button
                    fluid
                    primary
                    loading={loading}
                    disabled={loading}
                    size="large"
                    content="Reset password"
                  />
                </form>
              </>
            )}
          </div>
        </Layout>
      </div>
    );
  }
}
