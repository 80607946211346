import React, { useEffect } from 'react';
import './topNav.less';
import logoInverted from 'assets/logo-compact.svg';

import { ButtonCart } from 'components';

export function TopNav({ hasEvent }) {
  return (
    <div className="topNav">
      <img height="30px" className="topNav__logo" src={logoInverted} />
      {hasEvent && <ButtonCart />}
    </div>
  );
}
