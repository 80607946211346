import React from 'react';
import { Layout } from 'components/Layout';
import { Spacer } from 'components/Spacer';
import { request } from 'utils/api';
import { Component } from 'helpers';
import logo from 'assets/logo-black.svg';

import { Button, Input } from 'components';

import '../auth.less';

export default class ForgotPasswordScreen extends Component {
  static title = 'Forgot Password';

  state = {
    loading: false,
    error: null,
    success: false,
    fields: {},
  };

  setFields(name, value) {
    this.setState({
      touched: true,
      fields: {
        ...this.state.fields,
        [name]: value,
      },
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      error: null,
      loading: true,
    });
    try {
      await request({
        method: 'POST',
        path: '/1/auth/request-password',
        body: this.state.fields,
      });
      this.setState({
        success: true,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { error, fields, loading, success } = this.state;

    return (
      <div className="auth-screen">
        <Layout center>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <img
              src={logo}
              width={126}
              height={23}
              className={this.getElementClass('logo')}
            />
            <Spacer size="s" />
            {success && (
              <div className="message">
                <div className="header">Mail sent!</div>
                <p>
                  Please follow the instructions in the email we sent to{' '}
                  <span className="bold">{this.state.fields.email}</span>
                </p>
              </div>
            )}
            {!success && (
              <>
                <form onSubmit={this.onSubmit}>
                  {!error && <Spacer size="m" />}

                  <p style={{ marginTop: '1em' }}>
                    Enter your email address and we will send you a password
                    reset link.
                  </p>

                  <Spacer size="xs" />
                  {error && (
                    <div className="message error">{error.message}</div>
                  )}
                  <Spacer size="xs" />
                  <Input
                    label="Email"
                    placeholder="Enter your email address"
                    autoComplete="email"
                    name="email"
                    type="email"
                    required
                    fluid
                    value={fields.email || ''}
                    onChange={(e, { name, value }) =>
                      this.setFields(name, value)
                    }
                    error={error?.hasField?.('email')}
                  />
                  <Spacer size="m" />
                  <Button
                    fluid
                    primary
                    loading={loading}
                    disabled={loading}
                    size="large"
                    content="Request Password"
                  />
                </form>
              </>
            )}
          </div>
        </Layout>
      </div>
    );
  }
}
