import React from 'react';
import PropTypes from 'prop-types';
import VideoStream from './Stream';
import { Component } from 'helpers';
import { Loader } from '../Loader';

import './adaptive.less';

export default class AdaptiveVideo extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.ended = false;
  }

  componentDidMount() {
    const el = this.getVideoElement();
    if (!el) return;
    el.addEventListener('durationchange', this.props.onDurationChange);
    el.addEventListener('timeupdate', this.props.onTimeUpdate);
    el.addEventListener('ended', this.onEnded);
    el.addEventListener('playing', this.onPlaying);
    el.addEventListener('loadeddata', this.onLoadedData);
  }

  componentWillUnmount() {
    const el = this.getVideoElement();
    if (!el) return;
    el.removeEventListener('durationchange', this.props.onDurationChange);
    el.removeEventListener('timeupdate', this.props.onTimeUpdate);
    el.removeEventListener('ended', this.onEnded);
    el.removeEventListener('playing', this.onPlaying);
    el.removeEventListener('loadeddata', this.onLoadedData);
  }

  onLoadedData = () => {
    const el = this.getVideoElement();
    if (el && this.props.defaultStartTime) {
      el.currentTime = this.props.defaultStartTime;
    }
  };

  getVideoElement() {
    let el = this.ref.current;
    if (el.ref) {
      el = el.ref.current;
    }
    return el;
  }

  play() {
    this.ref.current.play();
  }

  setTime(time) {
    const el = this.getVideoElement();
    if (el) {
      if (!isNaN(el.duration)) {
        // Small offset to prevent the video from unloading
        time = Math.max(0, Math.min(time, el.duration - 0.001));
        //el.currentTime(time);
        el.currentTime = time;
      }
    }
  }

  jump(t) {
    const el = this.getVideoElement();
    if (el) {
      this.setTime(t);
    }
  }

  toggle() {
    const el = this.getVideoElement();
    if (el) {
      if (el.paused) {
        el.play();
      } else {
        el.pause();
      }
    }
  }

  play() {
    const el = this.getVideoElement();
    if (el) {
      el.play();
    }
  }

  pause() {
    const el = this.getVideoElement();
    if (el) {
      el.pause();
    }
  }

  onEnded = () => {
    this.ended = true;
  };

  onPlaying = () => {
    this.ended = false;
  };

  render() {
    const { loading } = this.props;

    return (
      <div {...this.getProps()}>
        {loading && <Loader />}
        {this.renderVideo()}
      </div>
    );
  }

  renderVideo() {
    const { video, controls, autoPlay } = this.props;

    if (video?.source === 'mux') {
      return (
        <VideoStream
          video={video}
          controls={controls}
          autoPlay={autoPlay}
          onStart={this.props.onStart}
          onEnd={this.props.onEnd}
          ref={this.ref}
        />
      );
    } else {
      return (
        <React.Fragment>
          <video
            disablePictureInPicture
            ref={this.ref}
            preload="auto"
            src={`${video?.playbackUrl}`}
            style={{
              width: '100%',
              display: 'block',
              backgroundColor: '#000',
            }}
            controls={controls && !!video?.playbackUrl}
            autoPlay={autoPlay}
          />
        </React.Fragment>
      );
    }
  }
}

AdaptiveVideo.propTypes = {
  controls: PropTypes.bool,
};

AdaptiveVideo.defaultProps = {
  controls: false,
  onTimeUpdate: () => {},
  onDurationChange: () => {},
};
