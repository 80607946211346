import React, {
  useReducer,
  useContext,
  useMemo,
  useEffect,
  createContext,
} from 'react';

import { VideoTimeContext } from './video-time';

export const ProductsContext = createContext({
  products: [],
});

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

function getTimelineTrackForTime(timeline, time) {
  return timeline.filter(({ start, end }) => {
    return time > start && time < end;
  });
}

export const ProductsProvider = ({ children, event }) => {
  const { currentTime } = useContext(VideoTimeContext);

  const [state, setState] = useLocalState({
    products: [],
  });

  const { productTimeline, products } = useMemo(() => {
    const mainStream = event.streams.find((s) => s.type === 'main');

    const products = (event.products || []).flatMap((p) => {
      return [p, ...(p.subProducts || [])];
    });

    return {
      productTimeline: mainStream?.products || [],
      products,
    };
  }, [event]);

  useEffect(() => {
    const items = getTimelineTrackForTime(productTimeline, currentTime);

    let currentProducts = items
      .map((item) =>
        products.find((p) => {
          return p.id === item.productId;
        })
      )
      .filter(Boolean);

    currentProducts = [
      ...new Map(currentProducts.map((item) => [item.id, item])).values(),
    ];

    const isSameProducts =
      currentProducts.length === state.products.length &&
      currentProducts.every((p, index) => {
        return state.products[index].id === p.id;
      });

    if (!isSameProducts) {
      setState({
        products: currentProducts,
      });
    }
  }, [products, productTimeline, currentTime]);

  return (
    <ProductsContext.Provider value={state}>
      {children}
    </ProductsContext.Provider>
  );
};
