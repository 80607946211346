// react-hot-loader needs to be imported
// before react and react-dom
import 'react-hot-loader';

import React, { StrictMode } from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { SENTRY_DSN } from 'utils/env';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';

import App from './App';

if (SENTRY_DSN && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: location.host === 'because.world' ? 'production' : 'staging',
  });
}

const Wrapper = () => (
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>
);

ReactDOM.render(<Wrapper />, document.getElementById('root'));
