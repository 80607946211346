import React from 'react';
import { Component } from 'helpers';
import { registerLayout } from 'helpers/screen';

import './minimal.less';

class MinimalLayout extends Component {
  render() {
    return <main {...this.getProps()}>{this.props.children}</main>;
  }
}

registerLayout('minimal', MinimalLayout);
