import React, { useState } from 'react';
import { Container } from './Container';
import { SheetWrapper } from './Sheet';

function findPage(path, pages) {
  const ids = path.split('/');
  let target = pages;
  ids.forEach((id) => {
    target = (target?.children || target).find((c) => c.id === id);
  });
  return target;
}

export const Sheet = ({
  open,
  onClose,
  root,
  className,
  inline,
  fullscreen,
  blocking = true,
  onFullScreen = () => {},
  pages,
  zIndex,
  overlay,
  snapPoints,
  ...props
}) => {
  const [pageState, setPage] = useState({
    direction: 0,
    path: root,
    key: 'root',
    props: {},
  });

  const onNavigate = (path, key, props) => {
    setPage({ key, path, props, direction: pageState.path === root ? 1 : 0 });
  };

  const onBack = () => {
    const { path, key, props } = pageState;
    const target = findPage(path, pages);
    if (target.parent) {
      onNavigate(target.parent, key, props);
    } else {
      onClose();
    }
  };

  const page = findPage(pageState.path, pages);
  const containerRef = document.getElementById('screen-content');

  const container = (
    <Container
      page={page}
      pageState={pageState}
      onClose={onClose}
      onBack={onBack}
      onNavigate={onNavigate}
      containerRef={containerRef}
      {...props}
    />
  );

  return (
    <SheetWrapper
      snapPoints={snapPoints}
      zIndex={zIndex}
      containerRef={containerRef}
      inline={inline}
      overlay={overlay}
      fullscreen={fullscreen}
      blocking={blocking}
      onFullScreen={onFullScreen}
      open={open}
      page={page}
      onClose={onClose}
      onBack={onBack}>
      {container}
    </SheetWrapper>
  );
};
