import React, { useContext, useEffect, useReducer, useState } from 'react';
import ReactDOM from 'react-dom';
import './overview.less';
import { UserContext } from 'contexts/user';
import { CartContext } from 'contexts/cart';
import { NotificationContext } from 'contexts/notification';
import { TrackingContext } from 'contexts/tracking';
import { IconArrowForward } from '../Icons';
import { Container } from '../Container';
import { formatAddress } from 'utils/formatting';
import { CartTotal } from '../CartTotal';
import { Button } from '../Button';
import { request } from 'utils/api';
import { Prompt } from '../Prompt';

import { useStripe } from '@stripe/react-stripe-js';

import { trackOrder } from 'utils/tracking';

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

export const Overview = ({ onNavigate, onClose }) => {
  const stripe = useStripe();
  const { me, shippingAddress, boot, setHasCart, hasCart, paymentMethod } =
    useContext(UserContext);

  const cart = useContext(CartContext);
  const notification = useContext(NotificationContext);
  const tracking = useContext(TrackingContext);

  const [state, setState] = useLocalState({
    error: null,
    loading: false,
  });

  const canCheckout = [shippingAddress, me?.email, paymentMethod].every(
    Boolean
  );

  useEffect(() => {
    boot();
  }, []);

  async function checkout() {
    setState({ loading: true });
    let data;
    try {
      const response = await request({
        method: 'POST',
        path: `/1/orders`,
        body: {
          paymentMethodId: paymentMethod.id,
        },
      });
      data = response.data;
      cart.clear();
    } catch (e) {
      setState({ loading: false });
      setState({ error: e });
      return;
    }

    const { intent } = data;

    if (intent.status === 'requires_action') {
      // Let Stripe.js handle the rest of the payment flow.
      const { error } = await stripe.confirmCardPayment(intent.client_secret);
      if (error) {
        setState({ error, loading: false });
        return;
      }
    }

    if (intent.status === 'requires_capture') {
      await confirmOrder(intent.id, order.id);
    }

    trackOrder(tracking, data.order);

    setHasCart(false);
    notification.add({ title: 'Purchase Complete!' });
    setState({ loading: false });
    onClose(true);
  }

  return (
    <div className="checkout-overview">
      {state.error &&
        ReactDOM.createPortal(
          <Prompt
            title="Purchase Unsuccessful"
            description={state.error.message}
            onClose={() => {
              setState({ error: null });
            }}
          />,
          document.body
        )}
      <Container>
        <div className="step" onClick={() => onNavigate('checkout/account')}>
          {me?.email ? (
            <div className="step__inner">
              <label>Account</label>
              <div className="value">{me?.email}</div>
            </div>
          ) : (
            <>Add Account</>
          )}
          <IconArrowForward />
        </div>
        <div className="step" onClick={() => onNavigate('checkout/shipping')}>
          {shippingAddress ? (
            <>
              <div className="step__inner">
                <label>Ship to</label>
                <div className="value">
                  {formatAddress(shippingAddress).map((addressPart) => {
                    return <div key={addressPart}>{addressPart}</div>;
                  })}
                </div>
              </div>
              <IconArrowForward />
            </>
          ) : (
            <>
              Add Shipping
              <IconArrowForward />
            </>
          )}
        </div>
        <div className="step" onClick={() => onNavigate('checkout/card')}>
          {paymentMethod ? (
            <>
              <div className="step__inner">
                <label>Card</label>
                <div className="value">
                  {paymentMethod.card.brand?.toUpperCase()} ending in{' '}
                  {paymentMethod.card.last4}
                </div>
              </div>
              <IconArrowForward />
            </>
          ) : (
            <>
              Add Card
              <IconArrowForward />
            </>
          )}
        </div>

        <div className="checkout__summary">
          <CartTotal
            showTax
            onError={(error) => {
              setState({ error });
            }}
          />
        </div>

        <Button
          loading={state.loading}
          primary
          disabled={!canCheckout}
          fluid
          onClick={checkout}>
          Buy Now
        </Button>
      </Container>
    </div>
  );
};
