import React, { useRef } from 'react';
import { SheetHeader } from './Header';
import { Container } from '../Container';

import { ModalSheet } from '../ModalSheet';

let interval;

function noScroll() {
  interval = setInterval(() => {
    document.body.style.overflow = 'hidden';
  }, 250);
  setTimeout(() => {
    clearInterval(interval);
  }, 1000);
}

export const SheetWrapper = ({
  open,
  onBack,
  onClose,
  page,
  children,
  containerRef,
  inline,
  fullscreen,
  onFullScreen,
  overlay,
  zIndex,
  snapPoints,
}) => {
  // 0.65 to match with video in small size
  snapPoints = snapPoints || [1, 0.65, 0];

  if (overlay) {
    snapPoints = [0, '95%'];
  }

  if (fullscreen && !zIndex) {
    zIndex = 1000;
  }

  const ref = useRef();

  return (
    <ModalSheet
      ref={ref}
      zIndex={zIndex}
      open={open}
      containerRef={containerRef}
      inline={inline}
      snapPoints={fullscreen ? null : snapPoints}
      initialSnap={1}
      onSnap={(v) => {
        const value = snapPoints[v];
        if (value === 1) {
          onFullScreen(true);
        } else {
          onFullScreen(false);
        }
      }}
      onClose={onClose}>
      <ModalSheet.Container style={{ paddingBottom: ref.current?.y }}>
        <ModalSheet.Header>
          <Container>
            <SheetHeader
              hasBack={page.parent}
              onClose={onClose}
              onBack={onBack}
              {...page.header}
            />
          </Container>
        </ModalSheet.Header>
        {children}
      </ModalSheet.Container>
      <ModalSheet.Backdrop onClick={() => onClose()} />
    </ModalSheet>
  );
};
