import React, { useContext, useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button } from '../Button';
import { Prompt } from '../Prompt';
import { Loader } from '../Loader';
import { IconCheckmark } from '../Icons';
import { Container } from '../Container';

import { request } from '../../utils/api';

import { UserContext } from 'contexts/user';
import './card.less';
import { uniqBy } from 'lodash';

import { sessionStorage } from 'utils/storage';

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

function fetchPaymentMethods() {
  return request({
    method: 'GET',
    path: '/1/payments/methods',
  });
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
      fontSmoothing: 'antialiased',
      fontWeight: '400',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hideIcon: true,
};

function getTmpPaymentMethods() {
  try {
    const templateParameters = sessionStorage.getItem('tmpPaymenthodMethods');
    if (templateParameters) {
      return JSON.parse(templateParameters);
    }
  } catch (e) {}
  return [];
}

function addToTmpPaymentMethods(paymentMethod) {
  const paymentMethods = getTmpPaymentMethods();
  sessionStorage.setItem(
    'tmpPaymenthodMethods',
    JSON.stringify([...paymentMethods, paymentMethod])
  );
}

export const Card = ({ onNavigate }) => {
  const user = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();

  const [state, setState] = useLocalState({
    loading: false,
    cardIsReady: false,
    paymentMethods: getTmpPaymentMethods().filter(Boolean),
    selected: user.paymentMethod?.id,
    submitLoading: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState({
      submitLoading: true,
    });

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setState({ error, submitLoading: false });
      return;
    }

    user.setPaymentMethod(paymentMethod);
    addToTmpPaymentMethods(paymentMethod);
    onNavigate('checkout');
  };

  const onCardElementChange = ({ complete }) => {
    setState({
      cardIsReady: complete,
    });
  };

  async function boot() {
    if (!user.token) {
      setState({
        showCardForm: true,
      });
      return;
    }
    setState({ loading: true });
    try {
      const { data: paymentMethods } = await fetchPaymentMethods();
      setState({
        paymentMethods: uniqBy(
          [...paymentMethods, user.paymentMethod, ...state.paymentMethods],
          'id'
        ).filter(Boolean),
        loading: false,
      });
    } catch (e) {
      setState({ loading: false, error: e });
    }
  }

  useEffect(() => {
    boot();
  }, []);

  return (
    <div className="checkout-card">
      {state.error &&
        ReactDOM.createPortal(
          <Prompt
            title="Failed to add card"
            description={state.error.message}
            onClose={() => {
              setState({ error: null });
            }}
          />,
          document.body
        )}

      <Container>
        {state.loading && (
          <div className="center">
            <Loader dark />
          </div>
        )}

        {!state.loading &&
          state.paymentMethods.map((paymentMethod) => {
            return (
              <div
                onClick={() => {
                  setState({
                    selected: paymentMethod.id,
                  });
                  user.setPaymentMethod(paymentMethod);
                  onNavigate('checkout');
                }}
                key={paymentMethod.id}
                className="paymentMethodItem">
                <div className="paymentMethodItem__card">
                  {paymentMethod.card.brand.toUpperCase()}
                </div>
                <div className="paymentMethodItem__number">
                  Ending in {paymentMethod.card.last4}
                </div>
                <div className="paymentMethodItem__checkmark">
                  {state.selected === paymentMethod.id && <IconCheckmark />}
                </div>
              </div>
            );
          })}

        {!state.loading && (
          <>
            <p
              style={{
                fontWeight: 600,
                fontSize: '16px',
              }}>
              Add New Card
            </p>

            <form onSubmit={handleSubmit}>
              <div
                style={{
                  border: '#EBF1F5 1px solid',
                  borderRadius: '8px',
                  padding: '9px 0px 9px 16px',
                  marginTop: '5px',
                }}>
                <CardElement
                  onFocus={() => setState({ selected: undefined })}
                  onChange={onCardElementChange}
                  options={CARD_ELEMENT_OPTIONS}
                />
              </div>

              <Button
                loading={state.submitLoading}
                primary
                fluid
                disabled={!state.cardIsReady}>
                Add Card
              </Button>
            </form>
          </>
        )}
      </Container>
    </div>
  );
};
