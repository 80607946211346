export const trackOrder = (tracking, order) => {
  tracking.addEvent(
    'buyflow-completed',
    {
      productId: order.productItems[0].product.id,
      orderId: order.id,
    },
    true
  );

  (order.productItems || []).forEach(({ product }) => {
    tracking.addEvent(
      'product-purchased',
      {
        productId: product.id,
        orderId: order.id,
      },
      true
    );
  });
};
