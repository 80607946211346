import { formatUsd } from './formatting';

function getPrice(product, useOriginalPrice = false) {
  const originalPrice = product.compareAtPrice || product.originalPrice;
  if (useOriginalPrice && originalPrice) {
    return originalPrice / 100;
  }
  return product.price / 100;
}

export function getProductPrice(product, useOriginalPrice = false) {
  if (product.price) return formatUsd(getPrice(product, useOriginalPrice));

  if (product.type === 'base' && product.subProducts?.length) {
    const min = Math.min(
      ...product.subProducts.map((subProduct) =>
        getPrice(subProduct, useOriginalPrice)
      )
    );

    const max = Math.max(
      ...product.subProducts.map((subProduct) =>
        getPrice(subProduct, useOriginalPrice)
      )
    );

    if (min === max) return formatUsd(min);
    return `${formatUsd(min)} - ${formatUsd(max)}`;
  }
  return '';
}
