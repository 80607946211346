import React, {
  useReducer,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { VideoTimeContext } from './video-time';

import { TrackingContext } from './tracking';

export const VideoContext = createContext();

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

function getTimelineTrackForTime(timeline, time) {
  return timeline.filter(({ start, end }) => {
    return time > start && time < end;
  });
}

export const VideoProvider = ({ children, event }) => {
  const { currentTime } = useContext(VideoTimeContext);
  const tracking = useContext(TrackingContext);

  const [state, setState] = useLocalState({
    products: [],
    muted: false,
    paused: false,
    videoRef: {},
    jumpTo: () => {},
    pause: () => {},
    play: () => {},
    mute: () => {},
    unmute: () => {},
    setVideoRef: (ref) => {
      setState({ videoRef: ref });
    },
    ended: false,
  });

  const videoHtml = state.videoRef?.current?.getVideoElement();

  useEffect(() => {
    setState({
      jumpTo: (value) => {
        if (state.videoRef?.current) {
          state.videoRef.current.jump(value);
        }
      },
      pause: () => {
        if (state.videoRef?.current) {
          state.videoRef.current.pause();
          setState({ paused: true });
        }
      },
      play: () => {
        if (state.videoRef?.current) {
          state.videoRef.current.play();
        }
      },
      mute: (value) => {
        if (videoHtml) {
          videoHtml.muted = value;
          setState({ muted: value });
        }
      },
      setVideoRef: (ref) => {
        setState({ videoRef: ref });
      },
    });
  }, [videoHtml]);

  useEffect(() => {
    if (state.ended !== state.videoRef?.current?.ended) {
      setState({ ended: state.videoRef?.current?.ended });
    }
  }, [state.videoRef?.current?.ended]);

  useEffect(() => {
    setState({ muted: videoHtml?.muted });
  }, [videoHtml?.muted]);

  useEffect(() => {
    setState({ paused: videoHtml?.paused });
  }, [videoHtml?.paused]);

  const { productTimeline, products, chapters } = useMemo(() => {
    const mainStream = event.streams.find((s) => s.type === 'main');
    if (!mainStream) return { products: [], productTimeline: [], chapters: [] };

    const products = (event.products || []).flatMap((p) => {
      return [p, ...(p.subProducts || [])];
    });

    let currentStart = 0;
    const parsedChapters = [];

    for (let i = 0; i < mainStream.chapters?.length; i++) {
      if (mainStream.chapters[i].end > currentStart) {
        parsedChapters.push({
          ...mainStream.chapters[i],
          start: currentStart,
        });
        currentStart = mainStream.chapters[i].end;
      }
    }

    return {
      productTimeline: mainStream.products || [],
      products,
      chapters: parsedChapters,
    };
  }, [event]);

  useEffect(() => {
    const items = getTimelineTrackForTime(productTimeline, currentTime);

    const currentProducts = items
      .map((item) =>
        products.find((p) => {
          return p.id === item.productId;
        })
      )
      .filter(Boolean);

    const chapter = chapters.find((c) => {
      return c.start <= currentTime && c.end >= currentTime;
    });

    const isSameProducts =
      currentProducts.length === state.products.length &&
      currentProducts.every((p, index) => {
        return state.products[index].id === p.id;
      });

    if (!isSameProducts || chapter !== state.chapter) {
      setState({
        products: currentProducts,
        chapter,
      });
    }
  }, [products, chapters, productTimeline, currentTime]);

  useEffect(() => {
    if (state.videoRef?.current) {
      const { chapter, products } = state;
      const meta = {
        relativeTimestamp: currentTime,
      };
      if (chapter) {
        meta.chapter = chapters.map((c) => c._id).indexOf(chapter._id);
        meta.chapterName = chapter?.name;
      }
      if (products[0]) {
        meta.productId = products[0]?.id;
      }
      tracking.setEventMeta(meta);
    } else {
      tracking.setEventMeta({});
    }
  }, [state.chapter, state.products, state.videoRef, currentTime]);

  return (
    <VideoContext.Provider value={state}>{children}</VideoContext.Provider>
  );
};
