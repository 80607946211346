import React from 'react';
import logoInverted from 'assets/logo-inverted.svg';
import { screen, Component } from 'helpers';
import './styles.less';

@screen
export default class LandingScreen extends Component {
  static layout = 'landing';
  static title = 'Contact';

  state = { success: false, email: '', loading: false };

  constructor(props) {
    super(props);
  }

  render() {
    const { success, error } = this.state;

    return (
      <div className="contact">
        <img className="logo" src={`${logoInverted}`} />
        <h1>There's a better way to shop.</h1>
        <p className="note">
          We're currently an invite-only service. Reach out if you are interested in connecting with us.
        </p>

        <h3>Contact us</h3>
        <p className="address">
          30 East 68th Street
          <br /> New York, NY 10065
          <br />
          <br />
          <a href="mailto:support@because.world">support@because.world</a>
        </p>

        {error && (
          <div className="error-message" role="region" aria-label="Email Form error">
            {error.message}
          </div>
        )}
      </div>
    );
  }
}
