import React from 'react';
import logoInverted from 'assets/logo-inverted.svg';
import { screen, Component } from 'helpers';
import './styles.less';

@screen
export default class LandingScreen extends Component {
  static layout = 'landing';
  static title = 'Waitlist';

  state = { success: false, email: '', loading: false };

  constructor(props) {
    super(props);
  }

  onSubmit = async (e) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    window?.gtag('event', 'subscribed_to_waitlist');
    e.preventDefault();
    const formData = new FormData();
    formData.append('EMAIL', this.state.email);

    try {
      await fetch('https://world.us14.list-manage.com/subscribe/post?u=fc4e7a55e242ae2198aabe4f6&id=8af59dc695', {
        headers: {
          accept: '*/*',
          'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: new URLSearchParams(formData).toString(),
        method: 'POST',
        mode: 'no-cors',
        //credentials: 'omit',
      });
      this.setState({ success: true, loading: false });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
      console.error(e);
    }
  };

  render() {
    const { success, error } = this.state;

    return (
      <div className="waitlist">
        <img className="logo" src={`${logoInverted}`} />
        <h1>There's a better way to shop.</h1>
        <p className="note">
          We're currently an invite-only service. Add your name to the waitlist if you want to receive updates.
        </p>

        {!success && !error && (
          <form
            onSubmit={this.onSubmit}
            id="email-form"
            name="email-form"
            data-name="Email Form"
            aria-label="Email Form">
            <label htmlFor="email" className="field-label">
              Join the Waitlist
            </label>
            <input
              className="text-field"
              type="email"
              name="email"
              onChange={(e) => this.setState({ email: e.target.value })}
              placeholder="Enter your email"
              id="email"
              required
              value={this.state.email}
            />

            <input type="submit" value={this.state.loading ? 'Please wait...' : 'Submit'} className="submit-button" />
          </form>
        )}
        {success && (
          <div className="success-message" role="region" aria-label="Email Form success">
            Thank you! You have joined the waitlist.
          </div>
        )}
        {error && (
          <div className="error-message" role="region" aria-label="Email Form error">
            {error.message}
          </div>
        )}
      </div>
    );
  }
}
