import { hot } from 'react-hot-loader/root';
import './app.less';

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import 'layouts/landing';
import 'layouts/minimal';

import Landing from './screens/Landing';
import Terms from './screens/Terms';
import Event from './screens/Event';
import EventInvite from './screens/EventInvite';
import Waitlist from './screens/Waitlist';
import Contact from './screens/Contact';
import { sessionStorage } from 'utils/storage';

import { ForgotPassword, ResetPassword } from './screens/Auth';

const searchParams = new URL(document.location).searchParams;

searchParams.get('devMode') &&
  sessionStorage.setItem('devMode', !!searchParams.get('devMode'));

const IS_IE = /*@cc_on!@*/ false || !!document.documentMode;

const App = () => {
  if (IS_IE) {
    return (
      <div
        style={{
          height: '100%',
          display: 'table',
          maxWidth: '800px',
          margin: '0 auto',
        }}>
        <div
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            padding: '50px',
          }}>
          <h1>Your browser is not currently supported</h1>
          <h2>
            We recommend using the latest version of Chrome, Safari, Firefox, or
            Microsoft Edge.
          </h2>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Switch>
          <Route path="/contact" component={Contact} exact />
          <Route path="/waitlist" component={Waitlist} exact />
          <Route path="/forgot-password" component={ForgotPassword} exact />
          <Route path="/reset-password" component={ResetPassword} exact />
          <Route path="/event/:id" component={Event} />
          <Redirect path="/events/:id" to="/event/:id" />
          <Route path="/event-invites/:id" component={EventInvite} />
          <Route path="/terms-and-privacy" component={Terms} />
          <Route path="/" component={Landing} />
        </Switch>
      </>
    );
  }
};

export default hot(App);
